import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Content from "./Content";
import Sidebar from "./Sidebar";
import "./Layout.css";

function Layout() {
  const [navbarTitle, setNavbarTitle] = useState("util.app");
  const navbarRef = useRef();
  const toggleButtonRef = useRef();

  useEffect(() => {
    navbarRef.current.SetNavbarTitle(navbarTitle);
  }, [navbarTitle]);

  const setNavbar = (val) => {
    setNavbarTitle(val);
  };

  const ToggleSidebarClick = () => {
    toggleButtonRef.current.ToggleSidebar();
  };

  const onShowFilterIcon = () => {
    navbarRef.current.ShowFilterIcon();
  };

  const onShowSignatureIcon = () => {
    navbarRef.current.ShowSignatureIcons();
  };

  const onHideFilterIcon = () => {
    navbarRef.current.HideFilterIcon();
  };

  const onShowCameraIcon = () => {
    navbarRef.current.ShowCameraIcon();
  }

  const onHideCameraIcon = () => {
    navbarRef.current.HideCameraIcon();
  }

  return (
    <div>
      <Sidebar ref={toggleButtonRef} />
      <Navbar ref={navbarRef} toggleBtn={ToggleSidebarClick}/>
      <Content
        navbarTitle={setNavbar}
        showFilterIcon={onShowFilterIcon}
        hideFilterIcon={onHideFilterIcon}
        showSignatureIcons={onShowSignatureIcon}
        showCameraIcon={onShowCameraIcon}
        hideCameraIcon={onHideCameraIcon}
      />
    </div>
  );
}

export default Layout;
