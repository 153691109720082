import React, { useEffect, useState } from "react";
import { Drains, Services } from "../../../components/db";
import HTMLReactParser from "html-react-parser";
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import css from "./TaskInfoPage.module.css";
import { t } from "i18next";

const TaskInfoPage = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const showInMapClicked = (e) => {
    window.open(
      "https://maps.google.com?q=" + data.latitude + "," + data.longitude
    );
    e.target.blur();
  };

  const getLink = () => {
    if (data.type == "DRAIN") {
      return "/task?uuid=" + data.serviceId.uuid + "&&type=SERVICE";
    }

    if (data.type == "SERVICE") {
      return "/task?uuid=" + data.drainId + "&&type=DRAIN";
    }
    console.error("Incorrect form type");
  };

  const goToReletedTask = () => {
    window.location.href = getLink();
  };

  const showCoordinates = () => {
    window.open(
      "https://maps.google.com?q=" +
        data.latitudeRecorded +
        "," +
        data.longitudeRecorded
    );
  };

  const getPlannedDates = () => {
    if (data.scheduleFrom && data.scheduleTo) {
      return `${fixDate(data.scheduleFrom[2])}.${fixDate(
        data.scheduleFrom[1]
      )}.${data.scheduleFrom[0]} -
      ${fixDate(data.scheduleTo[2])}.${fixDate(data.scheduleTo[1])}.${
        data.scheduleTo[0]
      }`;
    } else if (data.scheduleFrom) {
      return `${fixDate(data.scheduleFrom[2])}.${fixDate(
        data.scheduleFrom[1]
      )}.${data.scheduleFrom[0]}`;
    } else if (data.scheduleTo) {
      return `${fixDate(data.scheduleTo[2])}.${fixDate(data.scheduleTo[1])}.${
        data.scheduleTo[0]
      }`;
    } else {
      return t("notAvailable");
    }
  };

  const fixDate = (val) => {
    return val < 10 ? "0" + val : val;
  };

  useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        const drain = await getTask(props.uuid, props.type);
        setData(drain);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    doAsync();
  }, [props.coordinatesRecorded]);

  if (loading) return <p>{t("loading")}</p>;

  if (!data) {
    return <p>{t("loadError")}</p>;
  }

  return (
    <div className={css.mainDiv}>
      <div>
        <MDBRow className="mb-3">
          {data.latitude && data.longitude && (
            <MDBCol size="6">
              <MDBBtn
                className="bg-primaryDark"
                size="lg"
                style={{ width: "100%" }}
                onClick={showInMapClicked}
              >
                {t("map")}
                <MDBIcon icon="map" style={{ paddingLeft: "10px" }}></MDBIcon>
              </MDBBtn>
            </MDBCol>
          )}
          {props.coordinatesRecorded && (
            <MDBCol size="6">
              <MDBBtn
                className="bg-primaryDark"
                size="lg"
                style={{ width: "100%" }}
                onClick={showCoordinates}
              >
                GPS
                <MDBIcon
                  fas
                  icon="crosshairs"
                  style={{ paddingLeft: "10px" }}
                />
              </MDBBtn>
            </MDBCol>
          )}
          <MDBCol size="6">
            {data.type == "DRAIN" && data.serviceId !== null && (
              <MDBBtn
                color="light"
                size="lg"
                style={{ width: "100%" }}
                onClick={goToReletedTask}
              >
                <span>
                  die Bedienung
                  <MDBIcon
                    color="#00ff00"
                    icon="angle-right"
                    style={{ paddingLeft: "10px" }}
                  />
                </span>
              </MDBBtn>
            )}
            {data.type == "SERVICE" && data.drainId !== null && (
              <MDBBtn
                color="light"
                size="lg"
                style={{ width: "100%" }}
                onClick={goToReletedTask}
              >
                <span>
                  der Abfluss
                  <MDBIcon
                    color="#00ff00"
                    icon="angle-right"
                    style={{ paddingLeft: "10px" }}
                  />
                </span>
              </MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
      </div>

      <div></div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.additionalDeal")}</span>
        </div>
        <div>
          <span>{data.serviceId ?? t("notAvailable")}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("status")}</span>
        </div>
        <div>
          <span>{t(props.status)}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.car")}</span>
        </div>
        <div>
          <span>{data.processor.label}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.date")}</span>
        </div>
        <div>
          <span>{getPlannedDates()}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.orderNumber")}</span>
        </div>
        <div>
          <span>{data.number}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.customerNumber")}</span>
        </div>
        <div>
          <span>{data.pin}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.customerAddress")}</span>
        </div>
        <div>
          <span>{data.salutation}</span>
          <br />
          <span>
            {data.forname} {data.name}
          </span>
          <br />
          <span>
            {data.clientStreet} {data.clientStreetNumber}
          </span>
          <br />
          <span>
            {data.clientPostCode ?? t("notAvailable")}{" "}
            {data.clientCity ?? t("notAvailable")}
          </span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.assetNumber")}</span>
        </div>
        <div>
          <span>{data.pitNumber}</span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("task.info.diposal")}</span>
        </div>
        <div>
          <span>
            {data.pitStreet} {data.pitStreetNumber}
          </span>
          <br />
          <span>
            {data.pitPostcode}
            {data.pitCity}
          </span>
        </div>
      </div>

      <div className={css.taskInfo}>
        <div className={css.bold}>
          <span>{t("info")}</span>
        </div>
        <div className={css.infoSection}>
          <span>
            {HTMLReactParser(
              data.info
                .replace("\\n", "<br />")
                .replace("<p>", "<br /><br /><p>")
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TaskInfoPage;

async function getTask(uuid, type) {
  var data = {};
  if (type == "DRAIN") {
    data = await Drains.getByUuid(uuid);
  } else {
    data = await Services.getByUuid(uuid);
  }

  return data;
}
