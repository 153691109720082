import { Config } from "../db";
import Parameters, {
  parametersPlaceholders,
  SENDER,
  SENDER_ADDRESS,
} from "../Parameters";

const ConfirmationPdfLayout = {
  getHeader: async (doc, companyAddressHeight, objectWidth) => {
    const logo = await Config.get("confirmation-logo");

    if (logo == undefined) {
      toast.error("pdf.img.confirmationLogoNotFound");
    }

    const sender = await Parameters.findValueByKey(SENDER);
    let senderAddress = await Parameters.findValueByKey(SENDER_ADDRESS);
    senderAddress = senderAddress
      .replaceAll("\\n", "\r\n")
      .replace(parametersPlaceholders.date, getDate());

    const logoProps = doc.getImageProperties(logo);
    const aspectRatio = logoProps.width / logoProps.height;
    const logoHeight = objectWidth / aspectRatio;
    doc.addImage(logo, "PNG", 5, 5, objectWidth, logoHeight);

    doc.line(5, 107, 15, 107);
    doc.line(5, 207, 15, 207);

    doc.setFontSize(6);
    doc.text(sender, 20, companyAddressHeight);
    doc.setFontSize(10);

    doc.text(
      "Geschäftszeichen: AW DEZ ENT",
      objectWidth,
      companyAddressHeight,
      { align: "right" }
    );
    doc.text(senderAddress, objectWidth, companyAddressHeight + 10, {
      align: "right",
    });

    return doc;
  },
  getOnlyLogo: async(doc, objectWidth) => {
    const logo = await Config.get("confirmation-logo");

    if (logo == undefined) {
      toast.error("pdf.img.confirmationLogoNotFound");
    }

    // const sender = await Parameters.findValueByKey(SENDER);
    // let senderAddress = await Parameters.findValueByKey(SENDER_ADDRESS);
    // senderAddress = senderAddress
    //   .replaceAll("\\n", "\r\n")
    //   .replace(parametersPlaceholders.date, getDate());

    const logoProps = doc.getImageProperties(logo);
    const aspectRatio = logoProps.width / logoProps.height;
    const logoHeight = objectWidth / aspectRatio;
    doc.addImage(logo, "PNG", 5, 5, objectWidth, logoHeight);
    return doc;
  },

  getFooter: (doc) => {
    doc.setTextColor("gray");
    doc.setFontSize(6);

    //case 4 columns
    // const footerColumn1Width = 20;
    // const footerColumn2Width = 20 + 47.5;
    // const footerColumn3Width = 20 + 2 * 47.5;
    // const footerColumn4Width = 20 + 3 * 47.5;

    //case 3 columns
    const footerColumn2Width = 20;
    const footerColumn3Width = 20 + 56.6;
    const footerColumn4Width = 20 + 2 * 56.6;

    const footerHeight = 272;

    // doc.setFont("helvetica", "bold");
    // doc.text("Bankverbindung:", footerColumn1Width, footerHeight);

    // doc.setFont("helvetica", "normal");
    // doc.text("Erzgebirgssparkasse", footerColumn1Width, footerHeight + 3);
    // doc.text("BIC", footerColumn1Width, footerHeight + 6);
    // doc.text("WELADED1STB", footerColumn1Width + 10, footerHeight + 6);
    // doc.text("IBAN", footerColumn1Width, footerHeight + 9);
    // doc.text(
    //   "DE98370540003940044694",
    //   footerColumn1Width + 10,
    //   footerHeight + 9
    // );

    doc.setFont("helvetica", "bold");
    doc.text("Kontakt:", footerColumn2Width, footerHeight);

    doc.setFont("helvetica", "normal");
    doc.text("Tel.:", footerColumn2Width, footerHeight + 3);
    doc.text("038852 / 6210", footerColumn2Width + 10, footerHeight + 3);
    doc.text("Fax:", footerColumn2Width, footerHeight + 6);
    doc.text("038852 / 62123", footerColumn2Width + 10, footerHeight + 6);
    doc.text("Online:", footerColumn2Width, footerHeight + 9);
    doc.text(
      "www.azv-sude-schale.de",
      footerColumn2Width + 10,
      footerHeight + 9
    );

    doc.setFont("helvetica", "bold");
    doc.text("Sprechzeiten:", footerColumn3Width, footerHeight);

    doc.setFont("helvetica", "normal");
    doc.text("Mo - Do", footerColumn3Width, footerHeight + 3);
    doc.text("08:30 - 12:00 Uhr", footerColumn3Width + 10, footerHeight + 3);
    doc.text("", footerColumn3Width, footerHeight + 6);
    doc.text("13:00 - 16:00 Uhr", footerColumn3Width + 10, footerHeight + 6);
    doc.text("Fr", footerColumn3Width, footerHeight + 9);
    doc.text("08:30 - 13:00 Uhr", footerColumn3Width + 10, footerHeight + 9);

    doc.setFont("helvetica", "bold");
    doc.text("Verbandsvorsteherin:", footerColumn4Width, footerHeight);

    doc.setFont("helvetica", "normal");
    doc.text("Fr. Lindenau", footerColumn4Width, footerHeight + 3);

    doc.setFont("helvetica", "bold");
    doc.text("Geschäftsführender Leiter", footerColumn4Width, footerHeight + 6);

    doc.setFont("helvetica", "normal");
    doc.text("Hr. Johanssen", footerColumn4Width, footerHeight + 9);
    return doc;
  },
};

export default ConfirmationPdfLayout;

const getDate = () => {
  const date = new Date();
  return `${date.getDate() > 10 ? date.getDate() : "0" + date.getDate()}.${
    (date.getMonth()+1) > 10 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)
  }.${date.getFullYear()}`;
};

const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
