import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"
import { toast } from "react-toastify";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
//history.replaceState({}, "", "/");
window.addEventListener('offline', function(e) { toast.info("Du bist offline. Eine Synchronisierung mit util.flow ist nicht möglich.") });
window.addEventListener('online', function(e) { toast.info("Die Internetverbindung wurde wiederhergestellt. Synchronisierung ist möglich.") });

serviceWorkerRegistration.register();

reportWebVitals();
