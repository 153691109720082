import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Drains, Services } from "../components/db";
import Tools from "../components/Tools";
import FieldConfig from "../components/FieldConfig";
import Parameters from "../components/Parameters";
import {
  event_makeMandatory,
  event_makeOptional,
  event_popup,
} from "../pages/Task/TaskPage";
import { t } from "i18next";
import "./DropdownTarif.css";
import { status_started } from "../pages/FilterPage";


var iv = "";

const DropodownTariff = forwardRef((props, ref) => {
  //Modal
  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inputValue, setInputValue] = useState("noSelection");
  const [selectedValue, setSelectedValue] = useState(null);
  const [fieldConfig, setFieldConfig] = useState(null);
  const [isMandatory, setIsMandatory] = useState(false);
  let lockUpdate = false;

  useImperativeHandle(ref, () => ({
    SetInputVal(val) {
      setInputValue(val);
    },
  }));

  useEffect(() => {
    lockUpdate = true;
    const doAsync = async () => {
      try {
        setLoading(true);
        iv = inputValue;
        const data = await getData(props);
        const currentVal = await getCurrentValue(props, data);
        setSelectedValue(currentVal);
        setData(data);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
        checkFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        lockUpdate = false;
        setLoading(false);
      }
    }
    doAsync();
  }, [inputValue]);

  const onClickCallback = async (event, e) => {

    if(lockUpdate)
    {
      return;
    }

    await Drains.updateRecord(props.formUuid,{remark_mandatory: undefined})
    await Drains.updateRecord(props.formUuid,{charged_mandatory: undefined})
    await Drains.updateRecord(props.formUuid,{destinationId_mandatory: undefined})
    await Drains.updateRecord(props.formUuid,{length_mandatory: undefined})


    let mandatory = false;
    if (fieldConfig?.events?.length > 0) {
      const key = await Parameters.findKeyByValue(event.target.textContent);
      const fieldEvent = fieldConfig.events.filter((x) => x.eventSource == key);
      if (fieldEvent.length > 0) {
        fieldEvent.forEach((fe) => {
          switch (fe.eventType) {
            case event_makeMandatory:
              makeMandatory(fe.eventDestination);
              setIsMandatory(true);
              mandatory = true;
              break;
            case event_makeOptional:
              setOptional(fe.eventDestination);
            case event_popup:
              toggleShow();
              break;
            default:
              console.log("Incorrect event type: " + fe.eventType);
          }
        });
      } else {
        const hasEvent = fieldConfig.events.filter(
          (x) => x.eventType == event_makeMandatory
        );
        console.log(hasEvent)
        if (hasEvent && hasEvent.length > 0) {
          const element = document.getElementsByClassName(
            hasEvent[0].eventDestination
          );
          if (element[0]) {
            element[0].children[0].textContent =
              element[0].children[0].textContent.replace("*", "");
            Drains.updateRecord(props.formUuid, {
              [hasEvent[0].eventDestination + "_mandatory"]: false,
            });
          }
        }

        const hasOptionalEvent = fieldConfig.events.filter(
          (x) => x.eventType == event_makeOptional
        );

        if(hasOptionalEvent){
          const element = document.getElementsByClassName(
            hasOptionalEvent[0].eventDestination
          );
          console.log(element)
          if (element[0]) {
            if(!element[0].children[0].textContent.includes("*")){
              element[0].children[0].textContent += "*"
            }
            Drains.updateRecord(props.formUuid, {
              [hasOptionalEvent[0].eventDestination + "_mandatory"]: true,
            });
          }
        }
      }
    }

    let uuid = event.target.getAttribute("uuid");
    const label = event.target.textContent;

    const button = document.getElementsByClassName(dropodownClass)[0];

    button.setAttribute("uuid", uuid);
    button.textContent = label;
    props.setStatus(status_started);

    if (props.type == "service") {
      await Services.updateRecord(props.formUuid, { [props.cell]: uuid });
    } else {
      await Drains.updateRecord(props.formUuid, { [props.cell]: uuid });
    }

    props.reload();
  };

  const makeMandatory = (className) => {
    const element = document.getElementsByClassName(className);

    if (element[0] && !element[0].children[0].innerText.includes("*")) {
      element[0].children[0].innerText += "*";
      Drains.updateRecord(props.formUuid, { [className + "_mandatory"]: true });
    }
  };

  const setOptional = (className) => {
    const element = document.getElementsByClassName(className);
    console.log(className)
    if(element.length > 0){
      element[0].children[0].innerText = element[0].children[0].innerText.replace(
        "*",
        ""
      );
      Drains.updateRecord(props.formUuid, { [className + "_mandatory"]: false });
    }
  };

  const component = {
    padding: "15px",
  };

  const dropdownStyle = {
    width: "100%",
  };

  const dropdownItemStyle = {
    width: "80%",
    overflowY: "auto",
    maxHeight: "280px",
    overflowX: "hidden",
    fontSize: "normal",
  };

  const label = "<auswählen>";
  const dropodownClass = "dropdown_" + props.tableName;

  const checkFieldConfig = (fieldCfg) => {
    if (fieldCfg.mandatory && fieldCfg.readOnly) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and Read-only"
      );
    }

    if (!fieldCfg.visible && fieldConfig.fieldCfg) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and hidden"
      );
    }
  };

  if (loading) return <p>{t("loading")}</p>;

  if (!data) return <p>{t("noData")}</p>;

  return (
    <div style={component} className={fieldConfig.visible ? "" : "hidden"}>
      <label>
        {props.label}
        {fieldConfig.mandatory ? "*" : ""}
      </label>
      <MDBDropdown>
        <MDBDropdownToggle
          disabled={props.disabled}
          style={dropdownStyle}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          outline
          className={dropodownClass}
          uuid={selectedValue != null ? selectedValue.uuid : ""}
        >
          {selectedValue != null ? selectedValue.value : label}
        </MDBDropdownToggle>
        <MDBDropdownMenu style={dropdownItemStyle}>
          {data
            .sort((a, b) => (a.value > b.value ? 1 : -1))
            .map((x) => {
              return (
                <MDBDropdownItem key={x.uuid} onClick={onClickCallback}>
                  <MDBDropdownLink uuid={x.key}>{x.value}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>{t("warning")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <p>{t("event.01")}</p>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="light"
                className="text-white bg-primaryDark"
                onClick={toggleShow}
              >
                OK
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
});

export default DropodownTariff;

async function getData(props) {
  return await Parameters.getListTariff();

}

function isSpecialPitType(val) {
  return val.toLowerCase().startsWith("kläranlage zww");
}

const getCurrentValue = async (props, data) => {
  var selectedUuid = "";
  if (props.type?.toLowerCase() == "service") {
    selectedUuid = await Services.getSingleCellByUuid(
      props.formUuid,
      props.cell
    );
  } else {
    selectedUuid = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  }

  if (!selectedUuid) {
    return null;
  }

  if (data != undefined) {
    var selectedValue = data.filter((x) => x.key == selectedUuid)[0];
    return selectedValue;
  } else {
    return null;
  }
};
