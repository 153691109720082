import React from "react";
import { MDBSwitch } from "mdb-react-ui-kit";
import { t } from "i18next";
import "./ToggleSwitch.css";
import { useState, useEffect } from "react";
import { Drains } from "../components/db";
import FieldConfig from "../components/FieldConfig";
import { load } from "detectrtc";
import { status_started } from "../pages/FilterPage";

const ToggleSwitch = React.forwardRef((props,ref) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fieldConfig, setFieldConfig] = useState(null);
  let lockUpdate = false;

  useEffect(() => {
    lockUpdate = true;
    const doAsync = async () => {
      try {
        setLoading(true);
        const loadedData = await getData(props);
        setData(loadedData);
        props.onChange(loadedData);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        lockUpdate = false;
        setLoading(false);
      }
    }
    doAsync();
  }, []);

  const componentStyle = {
    padding: "15px",
  };

  const onChangeCallback = async (ev) => {
    props.onChange(ev.target.checked)
    if(lockUpdate){
      return;
    }
    props.setStatus(status_started);
    await Drains.updateRecord(props.formUuid, {
      [props.cell]: ev.target.checked,
    });

    return;
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div style={componentStyle} className={fieldConfig.visible ? "" : "hidden"}>
      <MDBSwitch
        id="flexSwitchCheckDefault"
        label={t("task.customerPresent")}
        defaultChecked={data}
        onChange={onChangeCallback}
        disabled={props.disabled}
        ref={ref}
      />
    </div>
  );
})

export default ToggleSwitch;

const getData = async (props) => {
  const value = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  return value;
};
