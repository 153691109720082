import { t } from "i18next";
import {
  MDBListGroup,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { LocalAttchments } from "../../../components/db";
import SingleAttachment from "./SingleAttachment";

const AttachmentsPage = (props) => {
  const style = {
    padding: "15px",
  };

  const btnStyle = {
    width: "100%",
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalUuid, setModalUuid] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        const files = await getAttachments(props.uuid);
        setData(files);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    doAsync();
  }, [props.completed]);

  if (loading) return <p>{t("loading")}</p>;

  if (!data) {
    return <p>{t("loadError")}</p>;
  }

  const showModal = (uuid) => {
    if (!uuid) {
      return;
    }
    setModalUuid(uuid);
    setModal(true);
  };

  const toggleShow = () => setModal(!modal);

  const removeOnClick = async () => {
    await LocalAttchments.removeAttachment(modalUuid);
    const selector = '[attachmentid="' + modalUuid + '"]';
    const element = document.querySelector(selector);
    element.parentElement.parentElement.parentElement.remove();
  };

  return (
    <div style={style}>
      {t("attachments.listName")}
      <MDBListGroup flush>
        {data?.map((attachment) => {
          return (
            <div key={attachment.uuid}>
              <SingleAttachment
                data={attachment}
                mimeType={attachment.mimeType}
                isArchived={props.archived}
                completed={props.completed}
                showModal={showModal}
              />
            </div>
          );
        })}
      </MDBListGroup>
      <MDBModal show={modal} setShow={setModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>{t("warning")}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {t("attachments.removeConfirmationModal.question")}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn
                color="light"
                onClick={() => {
                  toggleShow();
                }}
              >
                {t("no")}
              </MDBBtn>
              <MDBBtn
                color="danger"
                onClick={() => {
                  removeOnClick();
                  toggleShow();
                }}
              >
                {t("yes")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default AttachmentsPage;

async function getAttachments(uuid) {
  const data = await LocalAttchments.getByFormUuid(uuid);
  return data;
}
