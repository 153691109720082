import Webcam from "react-webcam";
import React, { useRef, useCallback, useState } from "react";
import "./CameraPage.css";
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit";
import { Drains, LocalAttchments } from "../../../components/db";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { t } from "i18next";
import Tools from "../../../components/Tools";
import { status_started } from "../../FilterPage";

const CameraPage = (props) => {
  const navigate = useNavigate();
  const [centredModal, setCentredModal] = useState(false);
  const toggleShow = () => setCentredModal(!centredModal);
  const [customImageName, setCustomImageName] = useState("");
  const [imageBlob, setImageBlob] = useState(null);

  const search = useLocation().search;
  const formUuid = new URLSearchParams(search).get("uuid");
  const formType = new URLSearchParams(search).get("type");

  useEffect(() => {
    props.navbarProps.navbarTitle(t("camera.title"));
    props.navbarProps.hideFilterIcon();
    setBackIcon();
  }, []);

  const setBackIcon = () => {
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
    backIcon.onclick = onBackClick;
  };

  const windowWidth = window.innerWidth;
  const cameraWidth = windowWidth - 20;
  const cameraHeight = (windowWidth * 9) / 16;

  const webcamRef = useRef(null);
  const captureBtnDivRef = useRef(null);
  const saveBtnsDivRef = useRef(null);

  const onBackClick = () => {
    navigate(`/task?uuid=${formUuid}&type=${formType}`, true);
  };

  const capture = useCallback(() => {
    console.log(webcamRef.current);
    webcamRef.current.video.pause();
    captureBtnDivRef.current.style.display = "none";
    saveBtnsDivRef.current.style.display = "block";
    const imageSrc = webcamRef.current.getCanvas();

    imageSrc.toBlob((x) => {
      setImageBlob(x);
    }, "image/jpg");
  }, [webcamRef]);

  const cancelImage = () => {
    captureBtnDivRef.current.style.display = "block";
    saveBtnsDivRef.current.style.display = "none";
    webcamRef.current.video.play();
  };

  const saveImage = () => {
    toggleShow();
    const fileName = getImageName();
    const imageName = customImageName.length > 0 ? customImageName : fileName;

    console.log(imageName);
    LocalAttchments.addAttachment({
      blob: imageBlob,
      name: imageName,
      fileName: fileName + ".png",
      mimeType: imageBlob.type + "+picture",
      uuid: uuidv4(),
      formId: formUuid,
    }).then(() => {
      Drains.updateRecord(formUuid,{state: status_started}).then(() => {
        cancelImage();
      })
      
    });
  };

  const getImageName = () => {
    const date = new Date();

    var name = "img_";
    name += date.getFullYear();
    name += date.getMonth < 10 ? "0" + date.getMonth() : date.getMonth();
    name += date.getDay();
    name += "_";
    name += date.getHours < 10 ? "0" + date.getHours() : date.getHours();
    name += date.getMinutes < 10 ? "0" + date.getMinutes() : date.getMinutes();
    name += date.getSeconds < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return name;
  };

  return (
    <div className="cameraContainer">
      <div className="cameraBox">
        <Webcam
          audio={false}
          height={cameraHeight}
          screenshotFormat="image/jpg"
          width={cameraWidth}
          ref={webcamRef}
          videoConstraints={{
            width: 1920,
            height: 1080,
            facingMode: "environment",
          }}
          className="camera"
          id="camera"
          minScreenshotHeight={1080}
          minScreenshotWidth={1920}
        />
        <div className="text-center" id="captureBtn" ref={captureBtnDivRef}>
          <MDBBtn
            size="lg"
            floating
            className="bg-primaryDark"
            onClick={capture}
          >
            <MDBIcon fas size="lg" icon="camera" />
          </MDBBtn>
        </div>

        <div
          className="text-center"
          style={{ display: "none" }}
          id="saveBtns"
          ref={saveBtnsDivRef}
        >
          <MDBBtn size="lg" rounded onClick={toggleShow}>
            <MDBIcon far size="lg" icon="save" />
            &nbsp;&nbsp;{t("camera.saveImg")}
          </MDBBtn>
          <MDBBtn
            size="lg"
            color="danger"
            rounded
            style={{ marginLeft: "10px" }}
            onClick={cancelImage}
          >
            <MDBIcon fas size="lg" icon="ban" />
            &nbsp;&nbsp;{t("camera.cancel")}
          </MDBBtn>
        </div>
      </div>

      <MDBModal tabIndex="-1" show={centredModal} setShow={setCentredModal}>
        <MDBModalDialog centered>
          <MDBModalContent className="modalWindow">
            <MDBModalHeader>
              <MDBModalTitle>{t("camera.enterFilename")}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color={Tools.detectDarkTheme ? "light" : "dark"}
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                contrast={Tools.detectDarkTheme()}
                onChange={(x) => setCustomImageName(x.target.value)}
              />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={toggleShow}>
                {t("cancel")}
              </MDBBtn>
              <MDBBtn
                color="light"
                className="bg-primaryUtil color-indicatorErrorText"
                onClick={saveImage}
              >
                {t("save")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default CameraPage;
