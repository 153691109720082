import { t } from "i18next";
import { MDBBtn, MDBBtnGroup } from "mdb-react-ui-kit";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Config } from "../components/db";
import Tools from "../components/Tools";
import "./Sidebar.css";

const Sidebar = forwardRef((props, ref) => {
  const backgroundRef = useRef();
  const sidebarRef = useRef();
  const [applicationLogo, setApplicationLogo] = useState(null);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    ToggleSidebar() {
      onBtnClick();
    },
  }));

  useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        const logo = await Config.get("application-logo");
        if(logo){       
          setApplicationLogo(logo);
        }
        else{
          const resp = await fetch('/util.app.png');
          const blb = await resp.blob();
          const logo = await Tools.convertBlobToBase64(blb);
          setApplicationLogo(logo);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    doAsync();


  }, []);

  const onBtnClick = () => {
    backgroundRef.current.classList.toggle("background-visible");
    sidebarRef.current.classList.toggle("sidebar-visible");
  };

  if (loading) return <></>;
  if (!applicationLogo)
    return (
      <p style={{ color: "red", fontWeight: "700" }}>
        {t("sidebar.AppLogoComponentError")}
      </p>
    );

  return (
    <div>
      <div ref={backgroundRef} className="background" id="sidebar-bg" onClick={onBtnClick}></div>
      <div ref={sidebarRef} className="sidebar" id="sidebar-sb">
        <div className="header bg-primaryUtil d-flex justify-content-center align-items-center color-onPrimary">
          <h4>{t("sidebar.appName")}</h4>
        </div>
        <div className="logo">
          <img src={applicationLogo} className="logoImage" />
        </div>
        <div className="menu">
          <div></div>
          <div>
            <MDBBtnGroup
              vertical
              aria-label="Vertical button group"
              className="button-group"
            >
              <MDBBtn color="light" disabled className="menuHeader sidebar-btn">
                {t("sidebar.menu.header")}
              </MDBBtn>
              <MDBBtn color="light" className="btn sidebar-btn">
                <Link to={{ pathname: "/" }} onClick={onBtnClick}>
                  {t("sidebar.menu.tasks")}
                </Link>
              </MDBBtn>
              <MDBBtn color="light" className="sidebar-btn">
                <Link to={{pathname: "/sync"}} onClick={onBtnClick}>
                  {t("sidebar.menu.sync")}
                </Link>
              </MDBBtn>
              <MDBBtn color="light" className="sidebar-btn">
                <Link to={{pathname: "/settings"}} onClick={onBtnClick}>
                {t("settings")}
                </Link>
              </MDBBtn>
            </MDBBtnGroup>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
