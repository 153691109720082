import React, { useEffect, useRef, useState } from "react";
import Dropodown from "../../../ui/Dropdown";
import NumberInput from "../../../ui/NumberInput";
import TextBox from "../../../ui/TextBox";
import {
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBBtnGroup
} from "mdb-react-ui-kit";
import DropodownTariff from "../../../ui/DropdownTariff";
import DropdownParameters from "../../../ui/DropdownParameters";
import { Config, Drains, LocalAttchments } from "../../../components/db";
import ToggleButton from "../../../ui/ToggleButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ToggleSwitch from "../../../ui/ToggleSwitch";
import {
  defaultCar,
  defaultDestination,
  defaultDriver,
  mapAfterCoordinates,
} from "../../SettingsPage";
import {
  status_archived,
  status_completed,
  status_conflict,
  status_created,
} from "../../FilterPage";

const DrainFormPage = (props) => {
  const navigate = useNavigate();
  const [state, forceUpdate] = useState({});
  const [modal, setModal] = useState(false);

  const toggleShow = () => setModal(!modal);

  const checkRef = useRef();

  const component = {
    padding: "15px",
    WebkitScrollbar: "none",
  };

  const getCoordinates = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          Drains.updateRecord(props.uuid, {
            latitudeRecorded: pos.coords.latitude,
            longitudeRecorded: pos.coords.longitude,
          }).then(() => {
            props.setCoordinatesRecorded(true);
            toast.success(t("coords.ok"), {
              onClose: () => {
                successModalOnClose(pos);
              },
            });
          });
        },
        (err) => {
          switch (err.code) {
            case 1:
              toast.error(t("coords.permissionDenied"));
              break;
            case 2:
              toast.error(t("coords.positionUnavailable"));
              break;
            case 3:
              toast.error(t("coords.timeout"));
              break;
            default:
              toast.error(t("coords.unknownError"));
          }
        },
        {
          maximumAge: 0,
          enableHighAccuracy: true,
        }
      );
    } else {
      toast.error(t("coords.notSupported"));
    }
  };

  const successModalOnClose = (pos) => {
    Config.get(mapAfterCoordinates).then((response) => {
      console.log(response);
      if (response) {
        window.open(
          "https://maps.google.com?q=" +
            pos.coords.latitude +
            "," +
            pos.coords.longitude
        );
      }
    });
  };

  const forceReload = () => {
    forceUpdate({ ...state });
  };

  const hideFilterIcon = () => {
    const filterIcon = document.getElementById("nav-filter");
    if (filterIcon) {
      filterIcon.classList.add("navbar-hidden");
    }
  };

  const onCompleteBtnClick = async () => {
    props.confirmModal();
  };

  const onSetStatusBackClick = async () => {
    await LocalAttchments.removeAllProtocolsByUuid(props.uuid);
    await Drains.updateRecord(props.uuid, { state: "CREATED" });
    props.setCompleted(false);
    props.setStatus(status_created)
    toast.success(t("task.statusBack"));
    
  };

  const setCameraBtnEvent = () => {
    const cameraBtn = document.getElementById("nav-camera");
    cameraBtn.onclick = onCameraBtnClick;
  };

  const onCameraBtnClick = () => {
    navigate(`/task/camera/?uuid=${props.uuid}&type=DRAIN`);
  };

  const [tariffInputValue, setTariffInputValue] = useState("noSelection");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [defaultDriver, setDefaultDriver] = useState(null);
  const [defaultCar, setDefaultCar] = useState(null);
  const [defaultDestination, setDefaultDestination] = useState(null);
  const tariffRef = useRef();

  useEffect(() => {
    if (tariffInputValue != "noSelection") {
      tariffRef.current.SetInputVal(tariffInputValue);
    }
  }, [tariffInputValue]);

  useEffect(() => {
    hideFilterIcon();
    const doAsync = async () => {
      try {
        setLoading(true);
        const data = await getData(props.uuid);
        if(data.latitudeRecorded && data.longitudeRecorded){
          props.setCoordinatesRecorded(true);
        }
        setData(data);
        setCameraBtnEvent();
        props.setCompleted(
          data.state == status_archived ||
            data.state == status_conflict ||
            data.state == status_completed
        );

        setDefaultDriver(await getDefaultDriver());
        setDefaultCar(await getDefaultCar());
        setDefaultDestination(await getDefaultDestination());

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };
    doAsync();
  }, [state]);

  const archiveManually = async () => {
    try {
      await Drains.updateRecord(props.uuid, {
        state: status_archived,
      });
      props.setStatus(status_archived)
      toast.success("task.archivedManually");
    } catch (err) {
      toast.error("task.changeFromConflictToArchivedError");
    }
  };

  if (loading) return <p>{t("loading")}</p>;

  if (!data) return <p>{t("loadError")}</p>;

  return (
    <div style={component}>
      <div className="d-flex justify-content-center">
        <MDBBtnGroup>
          <MDBBtnGroup>
            <MDBDropdown>
              <MDBDropdownToggle color="light" size="lg">
                {t("options")}
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                {
                  // data.state != status_archived &&
                  //   data.state != status_conflict &&
                  //   data.state != status_completed &&
                  !props.completed && (
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        onClick={getCoordinates}
                        style={{ fontSize: "medium" }}
                      >
                        {t("task.getCoordinates")}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  )
                }
                {data.state != status_archived &&
                  data.state != status_conflict && (
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        onClick={onSetStatusBackClick}
                        style={{ fontSize: "medium" }}
                      >
                        {t("task.restoreStatus")}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  )}
                {props.completed && (
                  <MDBDropdownItem>
                    <MDBDropdownLink
                      style={{ fontSize: "medium" }}
                      onClick={() => {
                        navigate("/task/previewpdf?uuid=" + props.uuid);
                      }}
                    >
                      {t("task.printConfirmation")}
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
                {
                  // data.state != status_archived &&
                  //   data.state != status_conflict &&
                  //   data.state != status_completed &&
                  !props.completed && (
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        style={{ fontSize: "medium" }}
                        disabled={props.completed}
                        onClick={() => {
                          navigate("/task/signature?uuid=" + props.uuid);
                        }}
                      >
                        {t("signature")}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  )
                }

                {data.state == status_conflict && (
                  <MDBDropdownItem>
                    <MDBDropdownLink
                      onClick={archiveManually}
                      style={{ fontSize: "medium" }}
                    >
                      {t("task.archiveManually")}
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                )}
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBBtnGroup>
          <MDBBtn
            size="lg"
            color="light"
            // style={{ width: "100%" }}
            onClick={onCompleteBtnClick}
            className="bg-primaryDark text-white "
            disabled={props.completed}
          >
            {t("task.complete")}
          </MDBBtn>
        </MDBBtnGroup>
      </div>
      <hr />

      <TextBox
        label={t("drain.location")}
        cell="location"
        formUuid={props.uuid}
        type={props.type}
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <NumberInput
        label={t("drain.width")}
        formUuid={props.uuid}
        cell="width"
        type={props.type}
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <NumberInput
        label={t("drain.tonnage")}
        formUuid={props.uuid}
        cell="tonnage"
        type={props.type}
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <NumberInput
        label={t("drain.length")}
        formUuid={props.uuid}
        cell="length"
        type={props.type}
        disabled={props.completed}
        data={data}
        setStatus={props.setStatus}
      />
      <Dropodown
        label={t("drain.pitType")}
        tableName="pitType"
        changeHandler={(val) => setTariffInputValue(val)}
        formUuid={props.uuid}
        cell="pitTypeId"
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <DropodownTariff
        label={t("drain.kind")}
        parameters="tariff"
        inputValue={tariffInputValue}
        ref={tariffRef}
        formUuid={props.uuid}
        cell="kind"
        disabled={props.completed}
        reload={forceReload}
        setStatus={props.setStatus}
      />
      <TextBox
        value={data.ordered}
        label={t("drain.remark")}
        formUuid={props.uuid}
        cell="remark"
        type={props.type}
        disabled={props.completed}
        data={data}
        setStatus={props.setStatus}
      />
      <DropdownParameters
        label={t("drain.option")}
        parameters="surcharge"
        formUuid={props.uuid}
        cell="option"
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <Dropodown
        label={t("drain.destination")}
        tableName="destination"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        cell="destinationId"
        disabled={props.completed}
        data={data}
        defaultValue={defaultDestination}
        setStatus={props.setStatus}
      />
      <TextBox
        value={data.latest}
        label={t("drain.latest")}
        formUuid={props.uuid}
        cell="latest"
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <TextBox
        value={data.ordered}
        disabled={props.completed}
        label={t("drain.ordered")}
        formUuid={props.uuid}
        cell="ordered"
        setStatus={props.setStatus}
      />
      <NumberInput
        label={t("drain.charged")}
        formUuid={props.uuid}
        cell="charged"
        type={props.type}
        disabled={props.completed}
        data={data}
        setStatus={props.setStatus}
      />
      <Dropodown
        label={t("drain.ok")}
        tableName="drainOk"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        cell="ok"
        disabled={props.completed}
        setStatus={props.setStatus}
      />
      <Dropodown
        label={t("drain.car")}
        tableName="vehicle"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        cell="executorId"
        vehicle
        disabled={props.completed}
        defaultValue={defaultCar}
        setStatus={props.setStatus}
      />
      <Dropodown
        label={t("drain.driver")}
        tableName="employee"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        cell="employeeId"
        disabled={props.completed}
        defaultValue={defaultDriver}
        setStatus={props.setStatus}
      />
      <ToggleSwitch
        label={t("task.customerPresent")}
        cell="customerPresent"
        type="drain"
        disabled={props.completed}
        formUuid={props.uuid}
        ref={checkRef}
        onChange={props.setCustomerPresent}
        setStatus={props.setStatus}
      />
      <ToggleButton
        formUuid={props.uuid}
        type="drain"
        cell="check"
        disabled={props.completed}
        setStatus={props.setStatus}
      />
    </div>
  );
};

export default DrainFormPage;

async function getData(uuid) {
  const data = await Drains.getByUuid(uuid);
  return data;
}

async function getDefaultDriver() {
  return await Config.get(defaultDriver);
}

async function getDefaultCar() {
  return await Config.get(defaultCar);
}

const getDefaultDestination = async () => {
  return await Config.get(defaultDestination);
};
