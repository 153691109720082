import { MDBInput } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Services, Drains } from "../components/db";
import Tools from "../components/Tools";
import FieldConfig from "../components/FieldConfig";
import { status_started } from "../pages/FilterPage";

const TextBox = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fieldConfig, setFieldConfig] = useState();
  let lockUpdate = false;

  useEffect(() => {
    lockUpdate = true;    
    const doAsync = async () => {
      try {   
        setLoading(true);
        const val = await getData(props);
        setData(val);
        const fieldCfg = await getFieldConfig(props.cell);
        setFieldConfig(fieldCfg);
        checkFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        lockUpdate = false;
        setLoading(false);
      }
    }
    doAsync();
  }, [])

  const css = {
    marginTop: "10px",
  };

  const componentStyle = {
    padding: "15px",
  };

  const onChangeCallback = async (ev) => {
    if(lockUpdate){
      return;
    }

    if (props.cell != undefined && props.type != undefined) {
      const recordToChange = {
        [props.cell]: ev.target.value,
      };
      props.setStatus(status_started);

      if (props.type == "service") {
        await Services.updateRecord(props.formUuid, recordToChange);
      } else {
        await Drains.updateRecord(props.formUuid, recordToChange);
      }
    }
  };

  

  const mandatory = () => {
    const selector = props.cell + "_mandatory";

    try{
      if(props.data[selector] !== undefined && props.data[selector] !== null){
        return props.data[selector];
      }
      else
      {
        return fieldConfig.mandatory;
      }
    }
    catch{
      return fieldConfig.mandatory
    }
  }



  const checkFieldConfig = (fieldCfg) => {
    if (fieldCfg.mandatory && fieldCfg.readOnly) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and Read-only"
      );
    }

    if (!fieldCfg.visible && fieldConfig.fieldCfg) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and hidden"
      );
    }
  };

  if (loading){
    return <p>Loading...</p>;
  } 

  return (
    <div style={componentStyle} className={props.cell + (fieldConfig.visible ? "" : " hidden")}>
      <label>{props.label}{(mandatory()) ? "*" : ""}</label>
      <MDBInput
        size="lg"
        onChange={onChangeCallback}
        disabled={props.disabled || fieldConfig.readOnly}
        style={css}
        defaultValue={data ?? ""}
        className={"textBox"}
        contrast={Tools.detectDarkTheme()}
      />
    </div>
  );
};

export default TextBox;

const getData = async (props) => {
  var data = null;

  if (props.type === "service") {
    data = await Services.getSingleCellByUuid(props.formUuid, props.cell);
  } else {
    data = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  }
  return data;
};

const getFieldConfig = async (fieldName) => {
  const config = await FieldConfig(fieldName);
  return config;
}