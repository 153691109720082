import { MDBBtn } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import {
  isChrome,
  isEdge,
  isFirefox,
  isIOS,
  isMobile,
  isMobileSafari,
  isSafari,
} from "react-device-detect";
import { toast } from "react-toastify";
import ShareIcon from "../components/svg/AppleShare";
import Tools from "../components/Tools";
import "./InstallPage.css";
import { t } from "i18next";

const InstallPage = (props) => {

  useEffect(() => {
    props.navbarProps.navbarTitle("Installation util.app");
    hideSidebarButton();
  }, []);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const chromeInstallEventListener = () => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  };

  const hideSidebarButton = () => {
    const btn = document.getElementById('nav-bars')
    btn.classList.add('navbar-hidden');

    const filterBtn = document.getElementById('nav-filter');
    filterBtn.classList.add('navbar-hidden')
  }

  const installOnClick = () => {
    if (Tools.detectIfDev()) {
      toast.info("Dev mode. Install app not allowed");
      return;
    }

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        toast.success(t("install.success"), {
          onClose: () => {
            console.log("OnClose!")
            window.close();
          },
        });
        install_button.style.display = "none";
      } else {
        console.log("User dismissed the prompt");
      }
      window.deferredPrompt = null;
    });
  };

  if (isChrome || isEdge) {
    chromeInstallEventListener();
    return (
      <div className="p-4">
        <h1>Willkommen bei util.app</h1>
        <p>
        bevor die App genutzt werden kann, muss sie installiert werden
        </p>
        <MDBBtn
          color="light"
          className="mt-3"
          size="lg"
          onClick={installOnClick}
        >
          Installation
        </MDBBtn>
      </div>
    );
  } else if (isMobileSafari) {
    return (
      <div className="p-4">
        <h1>"Willkommen bei util.app"</h1>
        <p>
          Before use app you must tap on <ShareIcon className="shareIcon" /> and
          then select "Add to homescreen"
        </p>
      </div>
    );
  } else if (isIOS && !isMobileSafari) {
    return (
      <div className="p-4">
        <h1>Welcome to util.app</h1>
        <p>To install util.app please use Safari</p>
      </div>
    );
  } else if (isFirefox && isMobile) {
    return <p>Firefox</p>;
  // } else if (isEdge) {
  //   return <p>Edge</p>;
  // } else if (isFirefox) {
    return (
      <div className="p-4">
        <h1>Welcome to util.app</h1>
        <p>
          PWA apps on Firefox desktop are no longer supported. Please use EDGE
          or Google Chrome instead.
        </p>
      </div>
    );
  } else if (isSafari) {
    <h1>Welcome to util.app</h1>;
    return (
      <p>
        Safari on macOS does not support PWA apps. Please install and use Google
        Chrome
      </p>
    );
  } else {
    return (
      <div className="p-4">
        <h1>Welcome to util.app</h1>
        <p>You are using unsupported browswer :-(</p>
        <p>Please use one from list:</p>
        <ul>
          <li>Windows: Google Chrome, Microsoft Edge</li>
          <li>Android: Google Chrome</li>
          <li>iOS: Safari</li>
          <li>macOS: Google Chrome</li>
        </ul>
      </div>
    );
  }
};

export default InstallPage;
