import React, { forwardRef, useState, useImperativeHandle } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBIcon } from "mdb-react-ui-kit";
import "./Navbar.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Navbar = forwardRef((props, ref) => {
  const [navbarTitle, setNavbarTitle] = useState("util.app");
  const location = useLocation();

  useEffect(() => {
    hideSignatureIcons();
    hideCameraIcon();
    hidePrinterIcon();
    hideBackIcon();
  }, [location.pathname]);

  useImperativeHandle(ref, () => ({
    SetNavbarTitle(val) {
      setNavbarTitle(val);
    },

    ShowFilterIcon() {
      showFilterIcon();
    },

    HideFilterIcon() {
      hideFilterIcon();
    },

    ShowSignatureIcons() {
      showSingatureIcons();
    },
    ShowCameraIcon() {
      showCameraIcon();
    },
    HideCameraIcon() {
      hideCameraIcon();
    },
  }));

  const showFilterIcon = () => {
    const navIcon = document.getElementById("nav-filter");
    navIcon.classList.remove("navbar-hidden");
  };

  const hideFilterIcon = () => {
    const navIcon = document.getElementById("nav-filter");
    navIcon.classList.add("navbar-hidden");
  };

  const showSingatureIcons = () => {
    const acceptIcon = document.getElementById("nav-acceptSignature");
    acceptIcon.classList.remove("navbar-hidden");

    const clearIcon = document.getElementById("nav-clearSignature");
    clearIcon.classList.remove("navbar-hidden");

    const cancelIcon = document.getElementById("nav-cancelSignature");
    cancelIcon.classList.remove("navbar-hidden");
  };

  const hideSignatureIcons = () => {
    const acceptIcon = document.getElementById("nav-acceptSignature");
    acceptIcon.classList.add("navbar-hidden");

    const clearIcon = document.getElementById("nav-clearSignature");
    clearIcon.classList.add("navbar-hidden");

    const cancelIcon = document.getElementById("nav-cancelSignature");
    cancelIcon.classList.add("navbar-hidden");
  };

  const showCameraIcon = () => {
    const cameraIcon = document.getElementById("nav-camera");
    cameraIcon.classList.remove("navbar-hidden");
  };

  const hideCameraIcon = () => {
    const cameraIcon = document.getElementById("nav-camera");
    cameraIcon.classList.add("navbar-hidden");
  };

  const hidePrinterIcon = () => {
    const printerIcon = document.getElementById("nav-printer");
    printerIcon.classList.add("navbar-hidden");
  };

  const hideBackIcon = () => {
    const backIcon = document.getElementById('nav-back');
    backIcon.classList.add('navbar-hidden');
  }

  return (
    <MDBNavbar
      expand="md"
      sticky
      className="px-4 justify-content-between navbar-primaryDark navbar color-onPrimary"
    >
      <div>
        <MDBIcon
          fas
          icon="bars"
          onClick={props.toggleBtn}
          className="navbar-iconLink"
          id="nav-bars"
        />
      </div>
      <div>
        <MDBNavbarBrand>
          <span className="color-onPrimary">{navbarTitle}</span>
        </MDBNavbarBrand>
      </div>
      <div className="icons">
        <MDBNavbarBrand>
          <MDBIcon
            id="nav-filter"
            fas
            icon="filter"
            className="color-onPrimary navbar-iconLink navbar-hidden"
          />
          <MDBIcon
            fas
            id="nav-acceptSignature"
            icon="check-double"
            className="color-onPrimary navbar-iconLink navbar-hidden"
          />
          <MDBIcon
            id="nav-clearSignature"
            fas
            icon="undo"
            className="color-onPrimary navbar-iconLink ms-4 navbar-hidden"
          />

          <MDBIcon
            id="nav-cancelSignature"
            fas
            icon="times"
            className="color-onPrimary navbar-iconLink ms-4 navbar-hidden"
          />

          <MDBIcon
            fas
            icon="camera"
            className="color-onPrimary navbar-iconLink ms-4 navbar-hidden"
            id="nav-camera"
          />

          <MDBIcon
            fas
            icon="arrow-left"
            id="nav-back"
            className="color-onPrimary navbar-iconLink ms-4 navbar-hidden"
          />

          <MDBIcon
            fas
            icon="print"
            className="color-onPrimary navbar-iconLink ms-4 navbar-hidden"
            id="nav-printer"
          />
        </MDBNavbarBrand>
      </div>
    </MDBNavbar>
  );
});

export default Navbar;
