import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.offline";
import { useEffect, useRef } from "react";
import {useLocation} from 'react-router-dom'

const Mappage_old = () => {
  const mapContainer = useRef();

  L.TileLayer.UtilMap = L.TileLayer.extend({
    _getTile: function () {
      console.log("test");
      return null;
    },
  });

  L.tileLayer.utilMap = function () {
    return new L.TileLayer.UtilMap();
  };

  useEffect(() => {
    console.log(mapContainer);
    const map = L.map("util.map");
    map.off();
    map.remove();

    L.tileLayer.utilMap().addTo(map);

    // offline baselayer, will use offline source if available
    // const layer = L.tileLayer
    // .offline('/gettilesoffline/{z}/{x}/{y}.png', {
    //   attribution: 'Map data {attribution.OpenStreetMap}',
    //   minZoom: 13
    // }).addTo(map);

    console.log(map);
  });

  return (
    <div style={{ width: "100vw", height: "93.9vh" }} id="util.map-container">
      <MapContainer
        ref={(x) => {
          mapContainer.current = x;
        }}
        minZoom={11}
        maxZoom={19}
        center={[51.35806157, 13.161621]}
        id="util.map"
        zoom={15}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="/tiles/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};

const Mappage = () => {
  const mapContainer = useRef();

  const search = useLocation().search;
  const lat = new URLSearchParams(search).get("lat");
  const lon = new URLSearchParams(search).get("lon");

  useEffect(() => {
    const map = L.map(mapContainer.current, {
      attributionControl: false,
    }).setView([lat, lon], 13);
    const layer = L.tileLayer
      .offline("/gettilesoffline/{z}/{x}/{y}.png", {
        attribution: "util.data",
        minZoom: 11,
        maxZoom: 19,
        zoom:15,
        scrollWheelZoom: true
      })
      .addTo(map);

      const icon = L.icon({
        iconUrl: '/img/location-pin.png',
    
        iconSize:     [50, 50], // size of the icon
        shadowSize:   [50, 64], // size of the shadow
        iconAnchor:   [64, 64], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62],  // the same for the shadow
        popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
    });

      const marker = L.marker([lat,lon],{
        icon: icon
      }).addTo(map);
  }, []);


  return (
    <div
      style={{ padding: 0, margin: 0, width: "100%", height: "93vh" }}
      ref={(el) => (mapContainer.current = el)}
    ></div>
  );
};

export default Mappage;
