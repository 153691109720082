import { t } from "i18next";
import {
  MDBBtn,
  MDBCheckbox,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBInput,
  MDBRange,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Config, getByTableName, Vehicle } from "../components/db";
import Tools from "../components/Tools";
import "./FilterPage.css";

export const sort_address_City_Street = "address-cityStreet";
export const sort_address_Street_City = "address-StreetCity";
export const sort_customer_Firstname_Name = "customer-firstnameName";
export const sort_customer_Name_Firstname = "customer-nameFirstname";
export const sort_type = "type";
export const sort_status = "status";
export const sort_car = "car";
export const sort_date = "date";

export const sortOrder_ascending = "ascending";
export const sortOrder_descending = "descending";

export const type_drain = "drain";
export const type_service = "service";

export const status_created = "CREATED";
export const status_started = "STARTED";
export const status_completed = "COMPLETED";
export const status_archived = "ARCHIVED";
export const status_conflict = "CONFLICT";
export const status_hideArchived = true;

export const carGroup_internal = "internal";
export const carGroup_external = "external";

function FilterPage(props) {
  const date = new Date();
  const [sortBy, setSortBy] = useState(sort_address_City_Street);
  const [sortOrder, setSortOrder] = useState(sortOrder_ascending);
  const [task, setTask] = useState(type_drain);
  const [status, setStatus] = useState(status_created);
  const [hideArchived, setHideArchived] = useState(status_hideArchived);
  const [carGroup, setCarGroup] = useState(carGroup_internal);
  const [car, setCar] = useState(t("select"));
  const [dateFrom, setDateFrom] = useState(date.toLocaleDateString("en-CA"));
  const [dateTo, setDateTo] = useState(date.toLocaleDateString("en-CA"));
  const [radius, setRadius] = useState(10000);

  const [isTaskChecked, toggleTask] = useState(false);
  const [isStatusChecked, toggleStatus] = useState(false);
  const [isCarGroupChecked, toggleCarGroup] = useState(false);
  const [isCarchecked, toggleCar] = useState(false);
  const [isDateFromChecked, toggleDateFrom] = useState(false);
  const [isDateFromTodayChecked, toggleDateFromToday] = useState(false);
  const [isDateToTodayChecked, toggleDateToToday] = useState(false);
  const [isDateToChecked, toggleDateTo] = useState(false);
  const [isRadiusChecked, toggleRadius] = useState(false);

  const [cars, setCars] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveBtnRef = useRef();

  const restoreDefault = () => {
    setSortBy(sort_address_City_Street);
    setSortOrder(sortOrder_ascending);
    setTask(type_drain);
    setStatus(status_created);
    setHideArchived(status_hideArchived);
    setCarGroup(carGroup_internal);
    setCar(t("select"));
    setDateFrom(date.toLocaleDateString("en-CA"));
    setDateTo(date.toLocaleDateString("en-CA"));
    setRadius(10000);
    toggleTask(false);
    toggleStatus(false);
    toggleCarGroup(false);
    toggleCar(false);
    toggleDateFrom(false);
    toggleDateTo(false);
    toggleRadius(false);
    toggleDateFromToday(false);
    toggleDateToToday(false);
  };

  const setValues = (cfg) => {
    if (cfg.sortBy) {
      setSortBy(cfg.sortBy);
    }

    if (cfg.sortOrder) {
      setSortOrder(cfg.sortOrder);
    }

    if (cfg.type) {
      setTask(cfg.type);
    }

    if (cfg.status) {
      setStatus(cfg.status);
    }

    if (cfg.hideArchived != undefined) {
      setHideArchived(cfg.hideArchived);
    }

    if (cfg.carGroup) {
      setCarGroup(cfg.carGroup);
    }

    if (cfg.car) {
      setCar(cfg.car);
    }

    if (cfg.dateFrom) {
      setDateFrom(cfg.dateFrom);
    }

    if (cfg.dateTo) {
      setDateTo(cfg.dateTo);
    }

    if (cfg.radius) {
      setRadius(cfg.radius);
    }

    if (cfg.isTaskChecked) {
      toggleTask(true);
    }

    if (cfg.isStatusChecked) {
      toggleStatus(true);
    }

    if (cfg.isCarGroupChecked) {
      toggleCarGroup(true);
    }

    if (cfg.isCarchecked) {
      toggleCar(true);
    }

    if (cfg.isDateFromChecked) {
      toggleDateFrom(true);
    }

    if(cfg.isDateFromTodayChecked){
      toggleDateFromToday(true);
    }

    if (cfg.isDateToChecked) {
      toggleDateTo(true);
    }

    if(cfg.isDateToTodayChecked){
      toggleDateToToday(true);
    }

    if (cfg.isRadiusChecked) {
      toggleRadius(true);
    }
  };


  useEffect(() => {
    const doAsync = async () => {
      try {
        props.navbarProps.navbarTitle("Filter");
        props.navbarProps.hideFilterIcon();
        setLoading(true);
        setBackIcon();
        const carsList = await getCars();
        setCars(carsList);
        const filtersConfig = await getData();
        if (filtersConfig) {
          setValues(filtersConfig);
        }
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    doAsync();
    setSaveIcon();
  },[])

  const setBackIcon = () => {
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
    backIcon.onclick = onBackClick;
  };

  const setSaveIcon = () => {
    const saveIcon = document.getElementById("nav-acceptSignature");
    saveIcon.replaceWith(saveIcon.cloneNode(true));
    const saveIcon2 = document.getElementById("nav-acceptSignature");
    saveIcon2.classList.remove("navbar-hidden");
    saveIcon2.onclick =  () => {
      saveBtnRef.current.click();
    };
  };

  const onBackClick = () => {
    navigate("/");
  };

  const onTypeCheckboxChange = (ev) => {
    toggleTask(ev.target.checked);
  };

  const onStatusCheckboxChange = (ev) => {
    toggleStatus(ev.target.checked);
  };

  const onHideArchivedCheck = (ev) => {
    setHideArchived(ev.target.checked);
  };

  const onCarGroupCheckboxChange = (ev) => {
    toggleCarGroup(ev.target.checked);
  };

  const onCarCheckboxChange = (ev) => {
    toggleCar(ev.target.checked);
  };

  const onDateFromCheckboxChange = (ev) => {
    if (ev.target.checked) {
      if (isDateFromTodayChecked) {
        toggleDateFromToday(false);
      }
      toggleDateFrom(true);
    } else {
      toggleDateFrom(false);
    }
  };

  const onDateFromTodayCheckboxChange = (ev) => {
    if (ev.target.checked) {
      if (isDateFromChecked) {
        toggleDateFrom(false);
      }
      toggleDateFromToday(true);
    } else {
      toggleDateFromToday(false);
    }
  };

  const onDateToCheckboxChange = (ev) => {
    if (ev.target.checked) {
      if (isDateToTodayChecked) {
        toggleDateToToday(false);
      }
      toggleDateTo(true);
    } else {
      toggleDateTo(false);
    }
  };

  const onDateToTodayCheckboxChange = (ev) => {
    if (ev.target.checked) {
      if (isDateToChecked) {
        toggleDateTo(false);
      }
      toggleDateToToday(true);
    } else {
      toggleDateToToday(false);
    }
  };
  const onRadiusCheckboxChange = (ev) => {
    toggleRadius(ev.target.checked);
  };

  const sortByOnChange = (ev) => {
    setSortBy(ev.target.getAttribute("val"));
  };

  const sortOrderOnChange = (ev) => {
    setSortOrder(ev.target.getAttribute("val"));
  };

  const taskTypeOnChange = (ev) => {
    setTask(ev.target.getAttribute("val"));
  };

  const statusOnChange = (ev) => {
    setStatus(ev.target.getAttribute("val"));
  };

  const carGroupOnChange = (ev) => {
    setCarGroup(ev.target.getAttribute("val"));
  };

  const carOnChange = (ev) => {
    setCar(ev.target.getAttribute("val"));
  };

  const dateFromOnChange = (ev) => {
    setDateFrom(ev.target.value);
  };

  const dateToOnChange = (ev) => {
    setDateTo(ev.target.value);
  };

  const radiusOnChange = (ev) => {
    setRadius(ev.target.value);
  };

  const onSave =  () => {
    const model = {
      sortBy: sortBy,
      sortOrder: sortOrder,
      type: task,
      status: status,
      carGroup: carGroup,
      car: car,
      dateFrom: dateFrom,
      dateTo: dateTo,
      radius: radius,
      isTaskChecked: isTaskChecked,
      isStatusChecked: isStatusChecked,
      isCarGroupChecked: isCarGroupChecked,
      isCarchecked: isCarchecked,
      isDateFromChecked: isDateFromChecked,
      isDateFromTodayChecked,
      isDateToChecked: isDateToChecked,
      isDateToTodayChecked,
      isRadiusChecked: isRadiusChecked,
      hideArchived,
    };

    Config.addOrUpdate({ key: "filters", value: model }).then(() => {
      navigate("/");
    });
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <div
      className="content"
      onLoad={(ev) => {
        console.log(ev);
      }}
    >
      <MDBBtn
        size="lg"
        color="light"
        style={{ color: "white", display: 'none' }}
        onClick={onSave}
        className="bg-primaryDark"
        ref={saveBtnRef}
      >
        {t("save")}
      </MDBBtn>
      <MDBBtn
        size="lg"
        color="light"
        style={{ color: "white" }}
        onClick={restoreDefault}
        className="color-primaryDark"
      >
        {t("filters.restoreDefault")}
      </MDBBtn>
      <MDBDropdown className="mt-4">
        <p>{t("sortBy")}</p>
        <MDBDropdownToggle color="light" size="lg" className="dropodown-btn">
          {t(sortBy)}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_customer_Firstname_Name}>
              {t(sort_customer_Firstname_Name)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_customer_Name_Firstname}>
              {t(sort_customer_Name_Firstname)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_address_City_Street}>
              {t(sort_address_City_Street)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_address_Street_City}>
              {t(sort_address_Street_City)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_type}>{t(sort_type)}</MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_status}>
              {t(sort_status)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          {/* <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_car}>{t(sort_car)}</MDBDropdownLink>
          </MDBDropdownItem> */}

          <MDBDropdownItem onClick={sortByOnChange} className="dd-sort">
            <MDBDropdownLink val={sort_date}>{t(sort_date)}</MDBDropdownLink>
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>

      <div className="spacer"></div>
      <p>{t("sortOrder")}</p>
      <MDBDropdown>
        <MDBDropdownToggle color="light" size="lg" className="dropodown-btn">
          {t(sortOrder)}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          <MDBDropdownItem onClick={sortOrderOnChange} className="dd-sortOrder">
            <MDBDropdownLink val={sortOrder_ascending}>
              {t(sortOrder_ascending)}
            </MDBDropdownLink>
          </MDBDropdownItem>

          <MDBDropdownItem onClick={sortOrderOnChange} className="dd-sortOrder">
            <MDBDropdownLink val={sortOrder_descending}>
              {t(sortOrder_descending)}
            </MDBDropdownLink>
          </MDBDropdownItem>
        </MDBDropdownMenu>
      </MDBDropdown>

      <div className="spacer"></div>
      <p>{t("type")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            className="checkbox"
            onChange={onTypeCheckboxChange}
            checked={isTaskChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBDropdown>
            <MDBDropdownToggle
              color="light"
              size="lg"
              className="dropodown-btn"
              disabled={!isTaskChecked}
            >
              {t(task)}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem onClick={taskTypeOnChange} className="dd-type">
                <MDBDropdownLink val={type_drain}>
                  {t(type_drain)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem onClick={taskTypeOnChange} className="dd-type">
                <MDBDropdownLink val={type_service}>
                  {t(type_service)}
                </MDBDropdownLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
      </MDBRow>

      <div className="spacer"></div>
      <MDBRow className="d-flex align-items-center">
        <p>{t("filters.hide_archived")}</p>
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onHideArchivedCheck}
            className="checkbox"
            checked={hideArchived}
          />
        </MDBCol>
      </MDBRow>
      <div className="spacer"></div>
      <p>{t("status")}</p>

      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onStatusCheckboxChange}
            className="checkbox"
            checked={isStatusChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBDropdown>
            <MDBDropdownToggle
              color="light"
              size="lg"
              className="dropodown-btn"
              disabled={!isStatusChecked}
            >
              {t(status)}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem onClick={statusOnChange} className="dd-status">
                <MDBDropdownLink val={status_created}>
                  {t(status_created)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem onClick={statusOnChange} className="dd-status">
                <MDBDropdownLink val={status_started}>
                  {t(status_started)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem onClick={statusOnChange} className="dd-status">
                <MDBDropdownLink val={status_completed}>
                  {t(status_completed)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem onClick={statusOnChange} className="dd-status">
                <MDBDropdownLink val={status_archived}>
                  {t(status_archived)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem onClick={statusOnChange} className="dd-status">
                <MDBDropdownLink val={status_conflict}>
                  {t(status_conflict)}
                </MDBDropdownLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
      </MDBRow>

      <div className="spacer"></div>
      <p>{t("carsGroup")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            className="checkbox"
            onChange={onCarGroupCheckboxChange}
            checked={isCarGroupChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBDropdown>
            <MDBDropdownToggle
              color="light"
              size="lg"
              className="dropodown-btn"
              disabled={!isCarGroupChecked}
            >
              {t(carGroup)}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem
                onClick={carGroupOnChange}
                className="dd-carGroup"
              >
                <MDBDropdownLink val={carGroup_internal}>
                  {t(carGroup_internal)}
                </MDBDropdownLink>
              </MDBDropdownItem>

              <MDBDropdownItem
                onClick={carGroupOnChange}
                className="dd-carGroup"
              >
                <MDBDropdownLink val={carGroup_external}>
                  {t(carGroup_external)}
                </MDBDropdownLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
      </MDBRow>

      <div className="spacer"></div>
      <p>{t("car")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onCarCheckboxChange}
            className="checkbox"
            checked={isCarchecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBDropdown>
            <MDBDropdownToggle
              color="light"
              size="lg"
              className="dropodown-btn"
              disabled={!isCarchecked}
            >
              {car}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              {cars.map((item) => {
                return (
                  <MDBDropdownItem key={item.uuid} onClick={carOnChange}>
                    <MDBDropdownLink val={item.label}>
                      {item.label}
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                );
              })}
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
      </MDBRow>

      <div className="spacer"></div>
      <p>{t("dateFrom")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onDateFromCheckboxChange}
            className="checkbox"
            checked={isDateFromChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBInput
            contrast={Tools.detectDarkTheme()}
            type="date"
            defaultValue={dateFrom}
            disabled={!isDateFromChecked}
            onChange={dateFromOnChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="d-flex align-items-center mt-4">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onDateFromTodayCheckboxChange}
            className="checkbox"
            checked={isDateFromTodayChecked}
          />
        </MDBCol>
        <MDBCol size="10">Heute</MDBCol>
      </MDBRow>
      <div className="spacer"></div>
      <p>{t("dateTo")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            onChange={onDateToCheckboxChange}
            size="lg"
            className="checkbox"
            checked={isDateToChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBInput
            disabled={!isDateToChecked}
            contrast={Tools.detectDarkTheme()}
            type="date"
            defaultValue={dateTo}
            onChange={dateToOnChange}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="d-flex align-items-center mt-4">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onDateToTodayCheckboxChange}
            className="checkbox"
            checked={isDateToTodayChecked}
          />
        </MDBCol>
        <MDBCol size="10">Heute</MDBCol>
      </MDBRow>

      <div className="spacer"></div>
      <p>{t("radius")}</p>
      <MDBRow className="d-flex align-items-center">
        <MDBCol size="2" className="d-flex justify-content-center">
          <MDBCheckbox
            size="lg"
            onChange={onRadiusCheckboxChange}
            className="checkbox"
            contrast={Tools.detectDarkTheme ? "dark" : "light"}
            checked={isRadiusChecked}
          />
        </MDBCol>
        <MDBCol size="10">
          <MDBRange
            defaultValue={radius}
            id="customRange"
            label={radius + "m"}
            min="100"
            max="10000"
            step="100"
            color={Tools.detectDarkTheme ? "light" : "dark"}
            disabled={!isRadiusChecked}
            onChange={radiusOnChange}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
}

export default FilterPage;

const getCars = async () => {
  const cars = await getByTableName.getList("vehicle");
  return cars;
};

const getData = async () => {
  if (!(await Config.checkIfExists("filters"))) {
    return null;
  }
  const data = await Config.get("filters");
  return data;
};
