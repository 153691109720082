import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import TasksPage from "../pages/TasksPage";
import FiltersPage from "../pages/FilterPage";
import FormPage from "../pages/FormPage";
import SettingsPage from "../pages/SettingsPage";
import SyncPage from "../pages/SyncPage";
import TaskPage from "../pages/Task/TaskPage";
import PreviewPdfPage from "../pages/PreviewPdfPage";
import CameraPage from "../pages/Task/Camera/CameraPage";
import SignaturePage from "../pages/Task/Signature/SignaturePage";
import DrainFormPage from "../pages/Task/DrainForm/DrainFormPage";
import Mappage from "../pages/Map/MapPage";
import ServiceFormPage from "../pages/Task/ServiceForm/ServiceFormPage";
import AttachmentsPage from "../pages/Task/Attachments/AttachmentsPage";
import ViewPdfPage from "../pages/ViewPdfPage";
import InstallPage from "../pages/InstallPage";
import CameraPage2 from "../pages/Task/Camera/CameraPage2";
import PrintErrorPage from "../pages/PrintError";

function Content(props) {
  return (
    <Routes>
      <Route exact path="/" element={<TasksPage navbarProps={props} />} />
      <Route
        exact
        path="/filters"
        element={<FiltersPage navbarProps={props} />}
      />
      <Route exact path="/form" element={<FormPage navbarProps={props} />} />
      <Route
        exact
        path="/settings"
        element={<SettingsPage navbarTitle={props.navbarTitle} navbarProps={props}/>}
      />
      <Route exact path="/sync" element={<SyncPage navbarProps={props} />} />
      <Route exact path="/task" element={<TaskPage navbarProps={props} />} />
      <Route
        exact
        path="/task/previewpdf"
        element={<PreviewPdfPage navbarProps={props} />}
      />
      <Route
        exact
        path="/task/viewpdf"
        element={<ViewPdfPage navbarProps={props} />}
      />
      <Route
        exact
        path="/task/camera"
        element={<CameraPage navbarProps={props} />}
      />
      <Route
        exact
        path="/task/camera2"
        element={<CameraPage2 navbarProps={props} />}
      />
      <Route
        exact
        path="/task/signature"
        element={<SignaturePage navbarProps={props} />}
      />
      <Route
        exact
        path="/task/form/drain"
        element={<DrainFormPage navbarTitle={props.navbarTitle} />}
      />
      <Route
        exact
        path="/task/form/service"
        element={<ServiceFormPage navbarTitle={props.navbarTitle} />}
      />
      <Route exact path="/print/error" element={<PrintErrorPage />} />
      <Route exact path="/task/map" element={<Mappage />} />
      <Route exact path="/task/attachments" element={<AttachmentsPage />} />
      <Route
        exact
        path="/install"
        element={<InstallPage navbarProps={props} />}
      />
    </Routes>
  );
}

export default Content;
