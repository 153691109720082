import { jsPDF } from "jspdf";
import { saveAs } from "file-saver";
import { Config, Drains, getByTableName, LocalAttchments } from "./db";
import { toast } from "react-toastify";
import "jspdf-autotable";
import { t } from "i18next";
import Parameters from "./Parameters";
import Tools from "./Tools";

export default function PdfCreator_OLD() {
  LocalAttchments.getAttachment(1).then((blob) => {
    saveAs(blob.blob, blob.fileName); //for later usage ;-)
  });
}

const pageWidth = 58;
const objectWidth = pageWidth - 10;

var vehicleName = "-";
var driverName = "-";
var kind = "-"

export const PdfCreator = {
  createPdf: async (uuid) => {
    
    const task = await Drains.getByUuid(uuid);
    if (task.kind) {
      kind = await Parameters.findValueByKey(task.kind)
    }

    const pre_doc = new jsPDF("p", "mm", [pageWidth, 100]);

    let kindLines = pre_doc.splitTextToSize(kind,70).length || 0;
    let remarkLines = pre_doc.splitTextToSize(task.remark, 70).length || 0
    let fontHeight = pre_doc.getTextDimensions(kind).h / pre_doc.internal.scaleFactor;

    const pageHeight = 100 +  ((kindLines-1) * 5) + (remarkLines * 4) + 8
    const doc = new jsPDF("p", "mm", [pageWidth, pageHeight],true);
    
    if (task.executorId) {
      const vehicle = await getByTableName.getRow("vehicle", task.executorId);
      vehicleName = vehicle.label;
    } else {
      vehicleName = task.processor.label;
    }

    if (task.employeeId) {
      const driver = await getByTableName.getRow("employee", task.employeeId);
      driverName = driver.label;
    }

    var logoHeight = 0
    const logo = await Config.get("recipe-header");
    // const logo = await blobToBase64(dbLogo)
    

    if (logo) {

      const logoProps = doc.getImageProperties(logo);
      const aspectRatio = logoProps.width / logoProps.height;
      logoHeight = objectWidth / aspectRatio;
      doc.addImage(logo, "PNG", 5, 5, objectWidth, logoHeight);
    }
    else{
      toast.error(t("pdf.img.confirmationLogoNotFound"));
    }

    doc.setFont("courier", "normal");

    let table = createData(task);
    let table2 = createData2(task);
    let table3 = createData3(task);

    const table1Y = logoHeight + 10;
    doc.autoTable({
      styles: {
        fillColor: [255, 0, 0],
        cellPadding: 0,
      },
      body: table,
      startY: table1Y,
      tableWidth: objectWidth,
      margin: {
        top: 0,
        bottom: 0,
        left: 5,
        right: 0,
      },
      showHead: "never",
      columnStyles: {
        0: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
          cellWidth: 20
        },
        1: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
        },
      },
    });

    const line1Y = table1Y + 35;
    doc.setDrawColor("gray");
    doc.line(5, line1Y, pageWidth - 5, line1Y);

    const table2Y = line1Y + 2;
    doc.autoTable({
      styles: {
        fillColor: [255, 0, 0],
        cellPadding: 0,
      },
      body: table2,
      startY: table2Y,
      tableWidth: objectWidth,
      margin: {
        top: 0,
        bottom: 0,
        left: 5,
        right: 0,
      },
      showHead: "never",
      columnStyles: {
        0: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
          cellWidth: 20
        },
        1: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
        },
      },
    });

    const line2Y = table2Y + 10;
    doc.setDrawColor("gray");
    doc.line(5, line2Y + ((kindLines-1)*fontHeight), pageWidth - 5, line2Y + ((kindLines-1)*fontHeight));

    const table3Y = line2Y;
    let finalTable3Y = {};
    doc.autoTable({
      styles: {
        fillColor: [255, 255, 0,0],
        cellPadding: [1,0,1,0],
      },
      body: table3,
      startY: table3Y + (kindLines*5)-3,
      tableWidth: objectWidth,
      margin: {
        top: 0,
        bottom: 0,
        left: 5,
        right: 0,
      },
      showHead: "never",
      columnStyles: {
        0: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
          cellWidth: 20
        },
        1: {
          halign: "left",
          fontSize: "7",
          fillColor: [255, 255, 255],
          textColor: 0,
        },
      },
      didParseCell: (d) => {finalTable3Y = d.table}
    });
  
    const signatureY = finalTable3Y.finalY;

    doc.setFontSize(7)
    doc.setFont('helvetica','normal')
    doc.text(5, signatureY + 3, "Kundenunterschrift");

    if(await LocalAttchments.checkIfExists(uuid, "mimeType", "signature")){
      const signature = await LocalAttchments.getAttachmentOtherValue(uuid,"mimeType","signature");
      const signatureBase64 = await blobToBase64(signature.blob);
      const signatureProps = doc.getImageProperties(signatureBase64);
      const signatureAspectRatio = signatureProps.width / signatureProps.height;
      const signatureHeight = objectWidth / signatureAspectRatio;

      doc.addImage(signatureBase64, "PNG", 5, signatureY+5, objectWidth, signatureHeight,'FAST');      
    }
    else{
      let text = "";
      if(task.customerPresent){
        text = t("pdf.confirmation.customerPresent");
      }
      else{
        text = t("pdf.confirmation.customerNotPresent");
      }
      doc.setFontSize(8)
      doc.setFont('helvetica','bold')
      var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
      var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(textOffset, signatureY+10, text);
    }

    var blobPDF = new Blob([doc.output("blob")], { type: "application/pdf" });
    return blobPDF;
  },

  createDrainConfirmation: async (uuid) =>{
    const drainConfirmation = new PdfDrainConfirmation();
    return await drainConfirmation.createPdf(uuid);
  }
};

const createData = (task) => {
  var data = [];
  data.push({
    label: "Auftragsnummer",
    value: task.number && task.number.length > 0 ? task.number : "-",
  });
  data.push({
    label: "Kundennummer",
    value: task.pin && task.pin.length > 0 ? task.pin : "-",
  });
  data.push({
    label: "Anlagennummer",
    value: task.pitNumber && task.pitNumber.length > 0 ? task.pitNumber : "-",
  });
  data.push({
    label: "Kunde",
    value:
      (task.salutation ? task.salutation + " " : "") +
      "\n" +
      (task.forname ? task.forname + " " : "") +
      (task.name != undefined ? task.name : "-") +
      "\n" +
      (task.clientStreet != undefined ? task.clientStreet : "-") +
      " " +
      (task.clientStreetNumber != undefined ? task.clientStreetNumber : "-") +
      "\n" +
      (task.clientPostCode != undefined ? task.clientPostCode : "-") +
      " " +
      (task.clientCity != undefined ? task.clientCity : "-"),
  });
  data.push({
    label: "",
    value: "",
  });
  data.push({
    label: "Fahrzeug",
    value: vehicleName,
  });
  data.push({
    label: "Fahrer",
    value: driverName,
  });

  return data;
};

const createData2 = (task) => {
  var data = [];
  data.push({
    label: "Schlauchlänge",
    value: task.length ? task.length +"m" : "-",
  });
  data.push({
    label: "Menge",
    value: task.charged ? task.charged +"m³": "-",
  });
  data.push({
    label: "Tarif",
    value: kind
  })
  return data;
};

const createData3 = (task) => {
  const date = Tools.timeStampToDate(task.date);
  var data = [];
  data.push({
    label: "Bemerkungen",
    value: task.remark && task.remark.length > 0 ? task.remark : "-"
  });
  data.push({
    label: "Datum",
    value: task.state == "COMPLETED" ?
      `${fixDate(date.getDate())}.${fixDate(date.getMonth()+1)}.${date.getFullYear()} ${fixDate(date.getHours())}:${fixDate(date.getMinutes())}`
       : ""
  });
  return data;
};

const fixDate = (date) => {
  if (parseInt(date) < 10) {
    return ("0" + date).toString();
  } else {
    return date.toString();
  }
};

const blobToBase64 = (blob) =>  {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
