import { MDBBtn } from "mdb-react-ui-kit";
import { Drains, Services } from "../components/db";
import { useEffect, useState } from "react";
import Tools from "../components/Tools";
import { t } from "i18next";
import FieldConfig from "../components/FieldConfig";
import { status_started } from "../pages/FilterPage";

const ToggleButton = (props) => {
  const componentStyle = {
    padding: "15px",
  };

  const toggle = async (event) => {
    if(lockUpdate){
      return;
    }

    var currentValue = event.target.getAttribute("value");

    if (currentValue == "true") {
      event.target.setAttribute("value", "false");
      event.target.textContent = t('toggleBtn.manual');
    } else {
      event.target.setAttribute("value", "true");
      event.target.textContent = t('toggleBtn.auto');
    }

    var cuurentValueBool = currentValue === "true";

    const recordToChange = {
      [props.cell]: cuurentValueBool,
    };

    props.setStatus(status_started);
    if (props.type == "service") {
      await Services.updateRecord(props.formUuid, recordToChange);
    } else {
      await Drains.updateRecord(props.formUuid, recordToChange);
    }
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fieldConfig, setFieldConfig] = useState(null);
  let lockUpdate = false;

  useEffect(() => {
    lockUpdate = true;
    const doAsync = async () => {
      try {
        setLoading(true);
        const val = await getData(props);
        setData(val);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        lockUpdate = false;
        setLoading(false);
      }
    }
    doAsync();
  }, []);

  if (loading) return <p>Loading...</p>;

  if (data == null)
    return (
      <p style={{ color: "red", fontWeight: "700" }}>
        Error in component {props.cell}
      </p>
    );

  return (
    <div style={componentStyle} className={fieldConfig.visible ? "" : "hidden"}>
      <MDBBtn
        rounded
        outline
        color={Tools.detectDarkTheme() ? "light" :"dark"}
        value="true"
        size="lg"
        style={{ width: "100%" }}
        onClick={toggle}
      >
        {t('toggleBtn.auto')}
      </MDBBtn>
    </div>
  );
};

export default ToggleButton;

const getData = async (props) => {
  var data = null;

  if (props.type == "service") {
    data = await Services.getSingleCellByUuid(props.formUuid, props.cell);
  } else {
    data = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  }

  return data;
};
