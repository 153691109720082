import { t } from "i18next";
import {
  MDBBtn,
  MDBCheckbox,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
} from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Config, Destination, Employee, Vehicle } from "../components/db";
import Tools from "../components/Tools";
import {text} from "../build.json";
import Printers from "../components/Printers";
import { useNavigate } from "react-router-dom";

function SettingsPage(props) {
  const [isLoading, setIsloading] = useState(true);
  const [drivers, setDrivers] = useState(null);
  const [cars, setCars] = useState(null);
  const [destinations, setDestinations] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(t("select"));
  const [selectedCar, setSelectedCar] = useState(t("select"));
  const [selectedDestination, setSelectedDestination] = useState(t("select"));
  const [selectedPrinter, setSelectedPrinter] = useState("noPrinterSelected");
  const [mapAfterCoordinatesChecked, setMapAfterCoordiantesChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const doAsync = async () => {
      setDrivers(await getDrivers());
      setCars(await getCars());
      setDestinations(await getDestinations());

      const storedDriver = await getDriver();
      if (storedDriver) {
        setSelectedDriver(storedDriver.label);
      }

      const storedCar = await getCar();
      if (storedCar) {
        setSelectedCar(storedCar.label);
      }

      const defaultPrinter = await getDefaultPrinter();
      if (defaultPrinter) {
        setSelectedPrinter(defaultPrinter);
      }

      const defaultDestination = await getDefaultDestination();
      if (defaultDestination) {
        setSelectedDestination(defaultDestination.label);
      }

      const coordinatesCheckboxChecked = await Config.get(mapAfterCoordinates);
      console.log(coordinatesCheckboxChecked)
      if(coordinatesCheckboxChecked != undefined && coordinatesCheckboxChecked != undefined){
        setMapAfterCoordiantesChecked(coordinatesCheckboxChecked);
      }
    };

    doAsync();
    props.navbarTitle(t("settings"));
    setBackIcon();
    props.navbarProps.hideFilterIcon();
    setIsloading(false);
  }, []);

  const onBackClick = () => {
    navigate("/");
  };

  const mapDropdownOnChange = (ev) => {
    setMapAfterCoordiantesChecked(ev.target.checked)
    Config.addOrUpdate({
      key: mapAfterCoordinates,
      value: ev.target.checked
    })
  };

  const setBackIcon = () => {
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
    backIcon.onclick = onBackClick;
  };

  if (isLoading) {
    return <p>{t("loading")}</p>;
  }

  if (!drivers || !cars) {
    return <p>{t("loadError")}</p>;
  }

  const saveDriver = async (ev) => {
    let driverUuid = ev.target.getAttribute("uuid");
    let driverName = ev.target.innerText;
    setSelectedDriver(driverName);

    await Config.addOrUpdate({
      key: defaultDriver,
      value: {
        uuid: driverUuid,
        label: driverName,
      },
    });
  };

  const saveCar = async (ev) => {
    let carUuid = ev.target.getAttribute("uuid");
    let carLabel = ev.target.innerText;
    setSelectedCar(carLabel);

    await Config.addOrUpdate({
      key: defaultCar,
      value: {
        uuid: carUuid,
        label: carLabel,
      },
    });
  };

  const saveDestination = async (ev) => {
    let uuid = ev.target.getAttribute("uuid");
    let label = ev.target.innerText;
    setSelectedDestination(label);

    await Config.addOrUpdate({
      key: defaultDestination,
      value: {
        uuid: uuid,
        label: label,
      },
    });
  };

  const savePrinter = async (ev) => {
    const key = ev.target.getAttribute("data-type");
    setSelectedPrinter(key);
    console.log(ev);
    if (key) {
      await Config.addOrUpdate({
        key: defaultPrinter,
        value: key,
      });
    }
  };

  const goToNewCamera = () => {
    window.location.href = "/task/camera2";
  };

  const getPrinterNameByKey = (key) => {
    if ("noPrinterSelected" == key) {
      return t("select");
    }

    const printer = Printers.filter((x) => {
      return key === x.key;
    })[0];
    return printer.name;
  };

  return (
    <div className="p-4">
      <h4>{t("settings.form")}</h4>
      <div className="pt-2"></div>
      <label>{t("drain.driver")}</label>
      <MDBDropdown>
        <MDBDropdownToggle
          style={{ width: "100%" }}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          outline
        >
          {selectedDriver}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {drivers
            .sort((a, b) => (a.sort > b.sort ? 1 : -1))
            .map((x) => {
              return (
                <MDBDropdownItem key={x.uuid} onClick={saveDriver}>
                  <MDBDropdownLink uuid={x.uuid}>{x.label}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
      <div className="pt-4"></div>
      <label>{t("drain.car")}</label>
      <MDBDropdown>
        <MDBDropdownToggle
          style={{ width: "100%" }}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          outline
        >
          {selectedCar}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {cars &&
            cars.map((x) => {
              return (
                <MDBDropdownItem key={x.uuid} onClick={saveCar}>
                  <MDBDropdownLink uuid={x.uuid}>{x.label}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
      <div className="pt-4"></div>
      <label>{t("drain.destination")}</label>
      <MDBDropdown>
        <MDBDropdownToggle
          style={{ width: "100%" }}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          outline
        >
          {selectedDestination}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {destinations &&
            destinations.map((x) => {
              return (
                <MDBDropdownItem key={x.uuid} onClick={saveDestination}>
                  <MDBDropdownLink uuid={x.uuid}>{x.label}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
      <div className="pt-4"></div>
      <h4>{t("settings.general")}</h4>
      <MDBCheckbox
        onChangeCapture={(ev) => {
          mapDropdownOnChange(ev);
        }}
        checked={mapAfterCoordinatesChecked}
        label={t("settings.mapAfterCoords")}
      />
      <div className="pt-4"></div>

      <h4>{t("settings.print")}</h4>
      <div className="pt-2"></div>
      <label>{t("printer.type")}</label>
      <MDBDropdown>
        <MDBDropdownToggle
          style={{ width: "100%" }}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          outline
        >
          {getPrinterNameByKey(selectedPrinter)}
        </MDBDropdownToggle>
        <MDBDropdownMenu>
          {Printers &&
            Printers.map((x) => {
              return (
                <MDBDropdownItem
                  key={x.key}
                  className="printerItem"
                  onClick={savePrinter}
                  data-type={x.key}
                >
                  <MDBDropdownLink data-type={x.key}>{x.name}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
      <div className="pt-4"></div>
      <hr />
      {`Version: ${text}`}

      {process.env.NODE_ENV && process.env.NODE_ENV === "development" && (
        <>
          {" "}
          <hr />
          <MDBBtn onClick={goToNewCamera}>
            New camera UI test. DO NOT USE!
          </MDBBtn>
        </>
      )}
    </div>
  );
}

export default SettingsPage;

export const defaultCar = "carCfg";
export const defaultDriver = "driverCfg";
export const defaultPrinter = "defaultPrinter";
export const defaultDestination = "defaultDestination";
export const mapAfterCoordinates = "showMapAfterGettingCoordinates";

async function getDrivers() {
  const drivers = await Employee.getList();
  drivers.push({
    uuid: Tools.uuidNull(),
    label: t("select"),
    sort: 0,
    valid: true,
  });
  return drivers;
}

async function getCars() {
  const cars = await Vehicle.getList();
  cars.push({
    uuid: Tools.uuidNull(),
    label: t("select"),
    sort: 0,
    valid: true,
  });
  return cars;
}

const getDestinations = async () => {
  const destinations = await Destination.getList();
  destinations.push({
    uuid: Tools.uuidNull(),
    label: t("select"),
    sort: 0,
    valid: true,
  });
  return destinations;
};

async function getDriver() {
  const driver = await Config.get(defaultDriver);
  return driver;
}

async function getCar() {
  const car = await Config.get(defaultCar);
  return car;
}

const getDefaultPrinter = async () => {
  const printer = await Config.get(defaultPrinter);
  return printer;
};

const getDefaultDestination = async () => {
  const dest = await Config.get(defaultDestination);
  return dest;
};
