import { t } from "i18next";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Drains, getByTableName, Services } from "../components/db";
import FieldConfig from "../components/FieldConfig";
import Tools from "../components/Tools";
import { status_created, status_started } from "../pages/FilterPage";

const Dropodown = forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [currentVal, setCurrentVal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fieldConfig, setFieldConfig] = useState(null);
  let lockUpdate = false;

  useImperativeHandle(ref, () => ({
    SomeFunctionForFututreUse(val) {},
  }));

  useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        const data = await getData(props);
        setData(data);
        const val = await getCurrentValue(props, data);
        setCurrentVal(val);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
        checkFieldConfig(fieldCfg);
        if (
          !val &&
          props.defaultValue &&
          props.defaultValue.uuid != Tools.uuidNull()
        ) {
          await saveChanges(props.defaultValue.uuid, true);
        }
        else if(val){
          saveChanges(val.uuid, true);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    doAsync();
  }, []);

  const onClickCallback = async (event, e) => {
    const uuid = event.target.getAttribute("uuid");
    const label = event.target.textContent;
    props.changeHandler(label);

    const button = document.getElementsByClassName(dropodownClass)[0];

    button.setAttribute("uuid", uuid);
    button.textContent = label;

    await saveChanges(uuid, false);
  };

  async function saveChanges(uuid, notChangeState) {
    const model = {
      [props.cell]: uuid,
    };
    if (notChangeState) {
      model.state = "do_not_change";
    }
    else{
      props.setStatus(status_started);
    }

    if (props.type == "service") {
      await Services.updateRecord(props.formUuid, model);
    } else {
      await Drains.updateRecord(props.formUuid, model);
    }
  }

  const checkFieldConfig = (fieldCfg) => {
    if (fieldCfg.mandatory && fieldCfg.readOnly) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and Read-only"
      );
    }

    if (!fieldCfg.visible && fieldCfg.mandatory) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and hidden"
      );
    }
  };

  var component = {
    padding: "15px",
  };

  const dropdownStyle = {
    width: "100%",
  };

  const dropdownItemStyle = {
    width: "80%",
    maxHeight: "280px",
    overflowY: "auto",
    fontSize: "normal",
    overflowX: "hidden",
  };

  const dropodownClass = "dropdown_" + props.tableName;

  function getDefaultLabel() {
    return props.defaultValue && props.defaultValue.uuid !== Tools.uuidNull()
      ? props.defaultValue.label
      : t("select");
  }

  function getDefaultUuid() {
    return props.defaultValue && props.defaultValue.uuid !== Tools.uuidNull()
      ? props.defaultValue.uuid
      : "";
  }

  const mandatory = () => {
    const selector = props.cell + "_mandatory";
    let result = false;
    try {
      result = props.data[selector];
      return result ?? fieldConfig.mandatory;
    } catch {
      return fieldConfig.mandatory;
    }
  };

  if (loading) return <p>{t("loading")}</p>;

  if (!data)
    return (
      <p style={{ color: "red", fontWeight: "700" }}>
        {t("loadError")} {props.cell}
      </p>
    );

  return (
    <div
      style={component}
      className={props.cell + (fieldConfig.visible ? "" : " hidden")}
    >
      <label>
        {props.label}
        {mandatory() ? "*" : ""}
      </label>
      <MDBDropdown>
        <MDBDropdownToggle
          style={dropdownStyle}
          uuid={currentVal ? currentVal.uuid : getDefaultUuid()}
          color={Tools.detectDarkTheme() ? "light" : "dark"}
          className={dropodownClass}
          outline
          disabled={props.disabled || fieldConfig.readOnly}
        >
          {currentVal ? currentVal.label : getDefaultLabel()}
        </MDBDropdownToggle>
        <MDBDropdownMenu style={dropdownItemStyle}>
          {data.map((x) => {
            return (
              <MDBDropdownItem key={x.uuid} onClick={onClickCallback}>
                <MDBDropdownLink uuid={x.uuid}>{x.label}</MDBDropdownLink>
              </MDBDropdownItem>
            );
          })}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
});

export default Dropodown;

async function getData(props, data) {
  var data = await getByTableName.getList(props.tableName);
  if (data[0].sort !== undefined) {
    data = data.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  } else {
    data = data.sort((a, b) => (a.label > b.label ? 1 : -1));
  }
  return data;
}

async function getCurrentValue(props, data) {
  var selectedUuid = "";
  if (props.type == "service") {
    selectedUuid = await Services.getSingleCellByUuid(
      props.formUuid,
      props.cell
    );
  } else {
    selectedUuid = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  }

  if (props.vehicle) {
    if (selectedUuid) {
      var selectedValue = data.filter((x) => x.uuid == selectedUuid)[0];
      return selectedValue;
    }
    if (props.defaultValue) {
      return null;
    }

    var val = null;
    if (props.type == "service") {
      val = await Services.getSingleCellByUuid(props.formUuid, "processor");
    } else {
      val = await Drains.getSingleCellByUuid(props.formUuid, "processor");
    }

    // if (props.type == "service") {
    //   await Services.updateRecord(props.formUuid, { "executorId": val.uuid, state: status_created });
    // } else {
    //   await Drains.updateRecord(props.formUuid, { "executorId": val.uuid, state: status_created });
    // }

    return {
      uuid: val.uuid,
      label: val.label,
    };
  }

  if (!selectedUuid) {
    return null;
  }

  if (data != undefined) {
    var selectedValue = data.filter((x) => x.uuid == selectedUuid)[0];
    return selectedValue;
  } else {
    return null;
  }
}
