import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationGer from "./locales/de-DE.json";
import translationGer_azv from "./locales/Ger_azv.json"

var translation;

switch(process.env.REACT_APP_CONF){
  case "azv":
    translation = translationGer_azv;
    break;
  default:
    translation = translationGer;
    break;
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: "ger",
    fallbackLng: "ger", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      ger: {
        translations: translation
      }
    },
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;