import React, { useEffect, useState } from "react";
import { isAndroid, isChrome, isIOS, isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import { PdfCreator } from "../components/PdfCreator";
import { Document, Page, pdfjs } from "react-pdf"
import { imageOverlay } from "leaflet";
import { defaultPrinter } from "./SettingsPage";
import { toast } from "react-toastify";
import { Config } from "../components/db";
import { EpsonTM } from "../components/Printers";
import { t } from "i18next";


const PreviewPdfPage = (props) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const search = useLocation().search;
  const uuid = new URLSearchParams(search).get("uuid");
  const navigate = useNavigate();
  var reader = new FileReader();
  var pdfData = "";
  var printer = null;
  const [pdfpreview, setpdfpreview] = useState(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/assets/js/pdf.worker.min.js`
    const doAsync = async() => {
      try {
        props.navbarProps.hideFilterIcon();
        props.navbarProps.navbarTitle("Druckansicht");
        addHandler();
        setPdfLoading(true);
        const dbPrinter = await Config.get(defaultPrinter);
        if(dbPrinter){
          printer = dbPrinter;
        }
  
        const pdfBlob = await readPdf(uuid);
        reader.onload = function () {
          pdfData = reader.result.substring(reader.result.indexOf(",") + 1);
          setpdfpreview(pdfData);
        };
        if (pdfBlob) {
          reader.readAsDataURL(pdfBlob);
        }
        if (isAndroid && isChrome) {
          setPdfFile(pdfBlob);
        } else {
          setPdfFile(URL.createObjectURL(pdfBlob));
        }
        shwowNavbarIcons();
        setPdfLoading(false);
      } catch (err) {
        setPdfLoading(false);
        console.error(err);
      }
    }
    doAsync();
  }, []);

  const shwowNavbarIcons = () => {
    //Windows browsers has implemented native preview and print mechanism and it is not required to show a print btn
    if (isMobile) {
      const printerIcon = document.getElementById("nav-printer");
      printerIcon.classList.remove("navbar-hidden");
    }
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
  };

  const printOnClick = () => {
    console.log(pdfData)
    if(isMobile && !printer){
      toast.warn(t("pdf.noPrinter"),{
        autoClose: 5000
      });
      return;
    }

    if (printer === EpsonTM && isMobile) {
      var query_success = "success";
      var query_ver = "ver";
      var query_datatype = "data-type";
      var query_data = "data";
      var query_reselect = "reselect";
      var query_cut = "cut";
      var query_fittowidth = "fit-to-width";
      var query_paperwidth = "paper-width";
      var query_errorDialog = "error-dialog"

      var appscheme = "tmprintassistant://";
      var host = "tmprintassistant.epson.com/";
      var action = "print?";
      var success = window.location.href;
      var ver = "1";
      var datatype = "pdf";
      var reselect = "yes";
      var cut = "feed";
      var fittowidth = "yes";
      var paperwidth = "58";
      var urlData = "";
      var errorDialog = "yes"


      urlData =
        appscheme +
        host +
        action +
        // query_success +
        // "=" +
        // encodeURIComponent(success) +
        // "&" +
        query_ver +
        "=" +
        ver +
        "&" +
        query_datatype +
        "=" +
        datatype +
        "&" +
        query_data +
        "=" +
        encodeURIComponent(pdfData) +
        "&" +
        query_reselect +
        "=" +
        reselect +
        "&" +
        query_cut +
        "=" +
        cut +
        "&" +
        query_fittowidth +
        "=" +
        fittowidth +
        "&" +
        query_paperwidth +
        "=" +
        paperwidth
        
        // + "&" + query_errorDialog + "=" + errorDialog
        // + "&error="+encodeURIComponent(window.location.origin + "/print/error");
      window.location.href = urlData;
      return;
    }

    // if (isChrome && isAndroid) {
    //   const canvas = document.getElementsByTagName("canvas");
    //   var img = new Image();
    //   img.id = "PDF";
    //   img.src = canvas[0].toDataURL();
    //   img.style.width = "58mm";

    //   const iframe = document.getElementById("print_pdf");
    //   console.log(iframe);

    //   const iframeBody = iframe.contentWindow.document.body;
    //   iframeBody.style.textAlign = "center";
    //   iframeBody.appendChild(img);

    //   img.addEventListener("load", function () {
    //     iframe.contentWindow.print();
    //   });
    //   return;
    // }

    const iframe = document.getElementById("iframeHidden");
    const iframeWindow = iframe.contentWindow;
    iframe.focus();
    iframeWindow.print();
  };

  const backOnClick = () => {
    navigate("/task?uuid="+uuid + "&&type=DRAIN")
  };

  const addHandler = () => {
    //Windows browsers has a implemented native preview and print mechanism and it is not required to show a print btn
    if (isMobile) {
      const printerIcon = document.getElementById("nav-printer");
      //printerIcon.addEventListener("click", printOnClick);
      printerIcon.onclick = printOnClick;
    }

    const backIcon = document.getElementById("nav-back");
    backIcon.onclick = backOnClick;
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const fitPDFonIOS = () => {
    if (!isIOS) {
      return;
    }

    const iframe = document.getElementById("main-iframe");
    console.log(iframe);
    const iframeHidden = iframe.cloneNode(true);
    iframeHidden.id = "iframeHidden";
    iframeHidden.style.display = "none";
    document.body.appendChild(iframeHidden);

    iframe.contentWindow.document.body.firstChild.style.width = "100%";
  };

  if (pdfLoading) return <p>{t("loading")}</p>;

  if (!pdfFile) {
    return <p>{t("loadError")}</p>;
  }

  if (isChrome && isAndroid) {
    //let iframe = document.createElement("iframe");

    if(!pdfpreview){
      return <p>{t("loading")}</p>
    }
    return (
      <div>
        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} width={window.innerWidth} />
        </Document>
        <iframe
          srcDoc='<html>
                    <style type="text/css" media="print">
                    @page {
                        size: auto;
                        margin: 0;
                        }

                    @media print {  
                      @page {
                        size: 58mm 150mm;
                      }
                    }
                    </style>
                    <body></body>
                  </html>'
          //style={{ display: "none" }}
          id="print_pdf"
        ></iframe>
      </div>
    );
  }

  return (
    <div>
      <iframe
        src={pdfFile + "#view=FitH"}
        id="main-iframe"
        style={{
          width: "100%",
          height: "93vh",
          position: "relative",
          top: 0,
          left: 0,
          border: "none",
        }}
        height="100%"
        width="100%"
        onLoad={fitPDFonIOS}
      ></iframe>
    </div>
  );
};

async function readPdf(uuid) {
  const pdf = await PdfCreator.createPdf(uuid);
  return pdf;
  //return URL.createObjectURL(pdf);
}

export default PreviewPdfPage;
