import { db } from "./db";



export const Tariff_prefix = "CG_KN_";

export const SENDER = "CG_KB_ABSENDER";
export const SENDER_ADDRESS = "CG_KB_TEXT"

export const parametersPlaceholders = {
    date: "${DATE}"
}

const Parameters =  {
    findValueByKey: async (key) =>{
        console.log(key)
       const row = await db.parameter.where({
        key: key
       }).first();
       return row.value;
    },
    findKeyByValue: async (value) =>{
        const row = await db.parameter.where({
            value: value
        }).first();
        return row.key
    },
    getListTariff: async () => {
        const parameters = await db.parameter.toArray();
        const tariffArray = parameters.filter(x => x.key.startsWith(Tariff_prefix));
        return tariffArray;
    }
}
export default Parameters;