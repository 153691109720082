import React, { useState, useEffect } from "react";
import TaskItem from "../ui/TaskItem";
import {
  Config,
  Drains,
  getByTableName,
  dbParameters,
  Tasks,
} from "../components/db";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tools from "../components/Tools";
import {
  carGroup_external,
  sortOrder_ascending,
  sortOrder_descending,
  sort_address_City_Street,
  sort_address_Street_City,
  sort_car,
  sort_customer_Firstname_Name,
  sort_customer_Name_Firstname,
  sort_date,
  sort_status,
  sort_type,
  status_archived,
} from "./FilterPage";
import { toast } from "react-toastify";
import { t } from "i18next";
import OneDateFilter from "./TasksPageComponents/OneDateFilter";

const TasksPage = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [severityKeywords, setWarningKeywords] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const doAsync = async () => {
      try {

        //redirectIfNotInstalled();
        setLoading(true);
        const dataSrc = await getDrains();
        const keywords = await getSeverityKeywords();
        const filtersCfg = await getFilters();
        const cars = await getCars();
        setFilterHandler();
        setWarningKeywords(keywords);
        await filterList(filtersCfg, dataSrc, cars);
        setLoading(false);     
        
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
    doAsync();

    props.navbarProps.navbarTitle("Aufträge (" + count + ")");
    props.navbarProps.showFilterIcon();
  }, []);

  useEffect(() => {
    props.navbarProps.navbarTitle("Aufträge (" + count + ")");
  })

  const redirectIfNotInstalled = () => {
    if (
      !Tools.detectInstalledPwa() &&
      !Tools.detectIfDev() &&
      !location.pathname.includes("/install")
    ) {
      navigate("/install", { replace: true }); //fix navbar!
    }
  };

  const setFilterHandler = () => {
    const filterIcon = document.getElementById("nav-filter");
    filterIcon.addEventListener("click", onFilterClick);
  };

  const onFilterClick = () => {
    navigate("/filters");
  };

  const filterList = async (cfg, data, cars) => {
    var filteredList = data;

    if (cfg == null || cfg.hideArchived) {
      filteredList = filteredList.filter((x) => x.state != status_archived);
    }

    setData(filteredList);

    if (!cfg) {
      setCount(filteredList.length);
      return;
    }

    if (cfg.isTaskChecked) {
      filteredList = filteredList.filter((x) => x.type == cfg.type);
    }

    if (cfg.isStatusChecked) {
      filteredList = filteredList.filter((x) => x.state == cfg.status);
    }

    if (cfg.isCarGroupChecked) {
      switch (cfg.carGroup) {
        case carGroup_external:
          filteredList = filteredList.filter((x) => {
            const uuid = x.executorId ? x.executorId : x.processorId;
            const car = cars.filter((y) => y.uuid == uuid);
            return car[0].label.toLowerCase().includes("extern");
          });
          break;
        case carGroup_external:
          filteredList = filteredList.filter((x) => {
            const uuid = x.executorId ? x.executorId : x.processorId;
            const car = cars.filter((y) => y.uuid == uuid);
            return !car[0].label.toLowerCase().includes("extern");
          });
          break;
      }
    }

    if (cfg.isCarchecked) {
      filteredList = filteredList.filter((x) => {
        const uuid = x.executorId ? x.executorId : x.processorId;
        const car = cars.filter((y) => y.uuid == uuid);
        return car[0].label == cfg.car;
      });
    }

    if (cfg.isDateFromChecked) {
      filteredList = filteredList.filter((x) => {

        const date1 = new Date(`${x.scheduleFrom[0]}/${x.scheduleFrom[1]}/${x.scheduleFrom[2]}`)
        const dateFilter = new Date(cfg.dateFrom.replace('-',"/"))
        //const date2 = new  Date(y.scheduleFrom[0],y.scheduleFrom[1],y.scheduleFrom[2])
        return date1 >= dateFilter;
      });
    }

    if(cfg.isDateFromTodayChecked){
      filteredList = filteredList.filter((x) => {
        const date1 = new Date(`${x.scheduleFrom[0]}/${x.scheduleFrom[1]}/${x.scheduleFrom[2]}`)
        const dateFilter = new Date(new Date().toDateString())
        //const date2 = new  Date(y.scheduleFrom[0],y.scheduleFrom[1],y.scheduleFrom[2])
        return date1 >= dateFilter;
      });
    }

    if (cfg.isDateToChecked) {
      filteredList = filteredList.filter((x) => {
        //const date1 = new Date(`${x.scheduleFrom[0]}/${x.scheduleFrom[1]}/${x.scheduleFrom[2]}`)
        const date1 = new Date(`${x.scheduleTo[0]}/${x.scheduleTo[1]}/${x.scheduleTo[2]}`)
        const dateFilter = new Date(cfg.dateTo.replace('-',"/"))
        return date1 <= dateFilter;
      });
    }
    
    if(cfg.isDateToTodayChecked){
      filteredList = filteredList.filter((x) => {
        //const date1 = new Date(`${x.scheduleFrom[0]}/${x.scheduleFrom[1]}/${x.scheduleFrom[2]}`)
        const date1 = new Date(`${x.scheduleTo[0]}/${x.scheduleTo[1]}/${x.scheduleTo[2]}`)
        const dateFilter = new Date(new Date().toDateString())
        return date1 <= dateFilter;
      });
    }

    if (cfg.isRadiusChecked) {
      var coords = null;
      try {
        coords = await getLocation();
      } catch (err) {
        console.log(err);
        switch (err) {
          case err.PERMISSION_DENIED:
            toast.error(
              t("coords.permissionDenied") + " " + t("tasks.filterNotWorks")
            );
            break;
          case err.POSITION_UNAVAILABLE:
            toast.error(
              t("coords.positionUnavailable") + " " + t("tasks.filterNotWorks")
            );
            break;
          case err:
            toast.error(t("coords.timeout") + " " + t("tasks.filterNotWorks"));
            break;
          case err.UNKNOWN_ERROR:
            toast.error(
              t("coords.unknownError") + " " + t("tasks.filterNotWorks")
            );
            break;
        }
      }

      if (coords) {
        filteredList = filteredList.filter((x) => {
          if (!x.latitude || !x.longitude) {
            return false;
          }
          const distance = findDistance(
            x.longitude,
            x.latitude,
            coords.coords.longitude,
            coords.coords.latitude
          ).toFixed(0);
          if (parseFloat(cfg.radius) >= distance) {
            return true;
          } else {
            return false;
          }
        });
      }
    }

    setCount(filteredList.length);
    sortList(filteredList, cfg);
  };

  const sortList = (list, cfg) => {
    var sortedList = list;
    switch (cfg.sortBy ? cfg.sortBy : sort_customer_Firstname_Name) {
      case sort_address_City_Street:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              if (x.pitCity === y.pitCity) {
                return x.pitStreet > y.pitStreet ? 1 : -1;
              }
              return x.pitCity > y.pitCity ? 1 : -1;
            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              if (x.pitCity === y.pitCity) {
                return x.pitStreet > y.pitStreet ? 1 : -1;
              }
              return x.pitCity > y.pitCity ? 1 : -1;
            });
            break;
        }
        break;
      case sort_address_Street_City:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              if (x.pitStreet === y.pitStreet) {
                return x.pitCity > y.pitCity ? 1 : -1;
              }
              return x.pitStreet > y.pitStreet ? 1 : -1;
            });
            console.log(sortedList);
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              if (x.pitStreet === y.pitStreet) {
                return x.pitCity > y.pitCity ? 1 : -1;
              }
              return x.pitStreet > y.pitStreet ? 1 : -1;
            });
            break;
        }
        break;
      case sort_customer_Firstname_Name:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              if (x.forname.concat(x.name) === y.forname.concat(y.name)) {
                return x.name > y.name ? 1 : -1;
              }
              return x.forname.concat(x.name) > y.forname.concat(y.name) ? 1 : -1;
            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              if (x.forname.concat(x.name) === y.forname.concat(y.name)) {
                return x.name > y.name ? 1 : -1;
              }
              return x.forname.concat(x.name) > y.forname.concat(y.name) ? 1 : -1;
            });
            break;
        }
        break;
      case sort_customer_Name_Firstname:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              if (x.name === y.name) {
                return x.forname > y.forname ? 1 : -1;
              }
              return x.name > y.name ? 1 : -1;
            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              if (x.name === y.name) {
                return x.forname > y.forname ? 1 : -1;
              }
              return x.name > y.name ? 1 : -1;
            });
            break;
        }
        break;
      case sort_type:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              return x.type > y.type ? 1 : -1;
            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              return x.type > y.type ? 1 : -1;
            });
            break;
        }
        break;
      case sort_status:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              return x.state > y.state ? 1 : -1;
            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              return x.state > y.state ? 1 : -1;
            });
            break;
        }
        break;
      case sort_car:
        break;
      case sort_date:
        switch (cfg.sortOrder ? cfg.sortOrder : sortOrder_ascending) {
          case sortOrder_ascending:
            sortedList = list.sort((x, y) => {
              const date1 = new Date(x.scheduleFrom[0],x.scheduleFrom[1],x.scheduleFrom[2])
              const date2 = new  Date(y.scheduleFrom[0],y.scheduleFrom[1],y.scheduleFrom[2])
              return date1 > date2 ? 1 : -1;

            });
            break;
          case sortOrder_descending:
            sortedList = list.sort((y, x) => {
              const date1 = new Date(x.scheduleFrom[0],x.scheduleFrom[1],x.scheduleFrom[2])
              const date2 = new  Date(y.scheduleFrom[0],y.scheduleFrom[1],y.scheduleFrom[2])
              return date1 > date2 ? 1 : -1;
            });
            break;
        }
        break;
    }

    setData(sortedList);
  };

  function checkSeverity(stringToCheck) {
    if(!severityKeywords || severityKeywords.length == 0){
      return "normal"
    }

    if (
      severityKeywords.warning.some((substring) =>
        stringToCheck.toLowerCase().includes(substring.toLowerCase())
      )
    ) {
      return "warning";
    }

    if (
      severityKeywords.error.some((substring) =>
        stringToCheck.toLowerCase().includes(substring.toLowerCase())
      )
    ) {
      return "error";
    }

    return "normal";
  }

  function findDistance(lon1, lat1, lon2, lat2) {
    var R = 6371e3; // R is earth’s radius
    var lat1radians = toRadians(lat1);
    var lat2radians = toRadians(lat2);
    var latRadians = toRadians(lat2 - lat1);
    var lonRadians = toRadians(lon2 - lon1);
    var a =
      Math.sin(latRadians / 2) * Math.sin(latRadians / 2) +
      Math.cos(lat1radians) *
        Math.cos(lat2radians) *
        Math.sin(lonRadians / 2) *
        Math.sin(lonRadians / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  function toRadians(val) {
    var PI = 3.1415926535;
    return (val / 180.0) * PI;
  }

  if (loading) return <p>{t("loading")}</p>;

  if (!data) return <p>{t("noData")}</p>;

  return (
    <div>
      {/* <OneDateFilter /> */}
      {data.map((item) => {
        return (
          <Link
            to={{
              pathname: "/task",
              search: "?uuid=" + item.uuid + "&type=" + item.type + '&tab=1'
            }}
            key={item.uuid}
          >
            <TaskItem
              drain={item}
              severity={checkSeverity(item.info)}
              type={item.type}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default TasksPage;

async function getDrains() {
  const data = await Tasks.getAll();
  return data;
}

async function getSeverityKeywords() {
  let warningKey = "CG_BI_WARN";
  let errorKey = "CG_BI_ERROR";

  var keywords = {
    error: [],
    warning: [],
  };

  let warningKeywords = await dbParameters.getParameterByKey(warningKey);
  let errorKeywords = await dbParameters.getParameterByKey(errorKey);

  keywords.warning = warningKeywords?.value.split(",") ?? [];
  keywords.error = errorKeywords?.value.split(",") ?? [];

  return keywords;
}

const getData = async (uuid, cell) => {
  const data = await Drains.getSingleCellByUuid(uuid, cell);
  return data;
};

const getFilters = async () => {
  const filters = await Config.get("filters");
  return filters;
};

const getCars = async () => {
  const cars = await getByTableName.getList("vehicle");
  return cars;
};

const getLocation = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    );
  });
};
