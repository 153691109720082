import jsPDF from "jspdf";
import { getByTableName, Services, LocalAttchments } from "../db";
import ConfirmationPdfLayout from "./ConfirmationPDFLayout";
import Tools from "../Tools";

export const PdfServiceConfirmation = {
  createPdf: async (uuid) => {
    var doc = jsPDF("p", "mm", "a4");

    const pageWidth = doc.internal.pageSize.getWidth();
    const objectWidth = pageWidth - 20;

    const task = await Services.getByUuid(uuid);

    var time = "";
    var vehicleName = "";
    var driverName = "";
    var signature = null;

    const companyAddressHeight = 55;
    doc = await ConfirmationPdfLayout.getHeader(
      doc,
      companyAddressHeight,
      objectWidth
    );

    if (task.timeId) {
      const selectedTime = await getByTableName.getRow("time", task.timeId);
      time = selectedTime.label + "h";
    }

    if (task.executorId) {
      const vehicle = await getByTableName.getRow("vehicle", task.executorId);
      vehicleName = vehicle.label;
    } else {
      vehicleName = task.processor.label;
    }

    if (task.employeeId) {
      const driver = await getByTableName.getRow("employee", task.employeeId);
      driverName = driver.label;
    }

    if (await LocalAttchments.checkIfExists(uuid, "mimeType", "signature")) {
      const attachment = await LocalAttchments.getAttachmentOtherValue(
        uuid,
        "mimeType",
        "signature"
      );
      signature = attachment.blob;
    }

    const addressHeight = companyAddressHeight + 12;
    doc.setFontSize(10);
    doc.text(task.salutation, 20, addressHeight); //salutation
    doc.text(task.forname + " " + task.name, 20, addressHeight + 5); // Name
    doc.text(
      (task.clientStreet ?? "") + " " + (task.clientStreetNumber ?? ""),
      20,
      addressHeight + 10
    ); // street
    doc.text(
      (task.clientPostCode ?? "") + " " + (task.clientCity ?? ""),
      20,
      addressHeight + 15
    );

    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    const titleHeight = 120;
    doc.text("Kundenbeleg Entleerungsaufrag", 20, titleHeight);

    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");

    const titleColumWidth = 100;
    const valueColumWidth = 110;
    const kundenNrHeight = titleHeight + 10;

    doc.text("Kundennummer:", titleColumWidth, kundenNrHeight, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.pin, valueColumWidth, kundenNrHeight);

    doc.setFont("helvetica", "bold");
    doc.text("Auftragsnummer:", titleColumWidth, kundenNrHeight + 5, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.number, valueColumWidth, kundenNrHeight + 5);

    doc.setFont("helvetica", "bold");
    doc.text("Entsorgungsort:", titleColumWidth, kundenNrHeight + 10, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(
      task.pitStreet + " " + task.pitStreetNumber,
      valueColumWidth,
      kundenNrHeight + 10
    );
    doc.text(
      (task.pitPostCode ?? "") + " " + (task.pitCity ?? ""),
      valueColumWidth,
      kundenNrHeight + 15
    );

    doc.setFont("helvetica", "bold");
    doc.text("Anlagennummer:", titleColumWidth, kundenNrHeight + 20, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.pitNumber, valueColumWidth, kundenNrHeight + 20);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("Bearbeitungszeit:", titleColumWidth, kundenNrHeight + 30, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(time, valueColumWidth, kundenNrHeight + 30);

    doc.setFont("helvetica", "bold");
    doc.setFontSize(10);
    doc.text("Bearbeitungsart:", titleColumWidth, kundenNrHeight + 40, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(getKinds(task), valueColumWidth, kundenNrHeight + 40);

    const kindsHeight = getTextHeight(task, getKinds(task), "kind", doc);
    const optionsHeight = kundenNrHeight + 40 + kindsHeight;

    doc.setFont("helvetica", "bold");
    doc.text("Nebenleistungen:", titleColumWidth, optionsHeight, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(getOptions(task), valueColumWidth, optionsHeight);

    const remarkHeight =
      optionsHeight + getTextHeight(task, getOptions(task), "options", doc);
    doc.setFont("helvetica", "bold");
    doc.text("Bemerkungen:", titleColumWidth, remarkHeight, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(task.remark ?? "", valueColumWidth, remarkHeight);

    doc.setFont("helvetica", "bold");
    doc.text("Fahrzeug:", titleColumWidth, kundenNrHeight + 65, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(vehicleName, valueColumWidth, kundenNrHeight + 65);

    doc.setFont("helvetica", "bold");
    doc.text("Fahrer:", titleColumWidth, kundenNrHeight + 70, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(driverName, valueColumWidth, kundenNrHeight + 70);

    doc.setFont("helvetica", "bold");
    doc.text("Datum:", titleColumWidth, kundenNrHeight + 80, {
      align: "right",
    });

    doc.setFont("helvetica", "normal");
    doc.text(getDate(), valueColumWidth, kundenNrHeight + 80);

    doc.setFont("helvetica", "bold");
    doc.text("Kundenunterschrift:", titleColumWidth, kundenNrHeight + 90, {
      align: "right",
    });

    if (signature != null) {
      const signatureImg = await Tools.convertBlobToBase64(signature);
      const signatureWidth = 80;
      const signatureImgProps = doc.getImageProperties(signatureImg);
      const aspectRatio = signatureImgProps.width / signatureImgProps.height;
      const signatureHeight = signatureWidth / aspectRatio;

      doc.addImage(
        signatureImg,
        "PNG",
        valueColumWidth,
        kundenNrHeight + 85,
        signatureWidth,
        signatureHeight
      );
    } else {
      doc.setFont("helvetica", "normal");
      doc.text("Kunden nicht anwesend", valueColumWidth, kundenNrHeight + 90);
    }

    doc = await ConfirmationPdfLayout.getFooter(doc);

    var blobPDF = new Blob([doc.output("blob")], { type: "application/pdf" });
    return blobPDF;
  },
};

const getKinds = (task) => {
  var output = "";
  if (task.kind.includes("EFFORT")) {
    output += "Einsatzzeit\n";
  }

  if (task.kind.includes("DOWNTIME")) {
    output += "Stillstandzeit";
  }

  return output;
};

const getOptions = (task) => {
  var output = "";

  if (task.options.includes("FLUSH")) {
    output += "Rohrspülung\n";
  }

  if (task.options.includes("CLEAN")) {
    output += "Grubenreinigung\n";
  }

  if (task.options.includes("SHUTDOWN")) {
    output += "Grubenstilllegung\n";
  }

  if (task.options.includes("INOCULATE")) {
    output += "Animpfen\n";
  }

  if (task.options.includes("FILL")) {
    output += "Befüllung mit Wasser\n";
  }
  if (task.options.includes("OTHER")) {
    output += "andere Leistung";
  }

  return output;
};

const getTextHeight = (task, text, cell, doc) => {
  const lenght = task[cell].length;
  const dim = doc.getTextDimensions(text);
  return (1 + dim.h) * lenght;
};

const getDate = () => {
  const date = new Date();
  return (
    monthShortNames[date.getMonth()] +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
};

const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
