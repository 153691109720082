import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { LocalAttchments } from "../../../components/db";
import "./SingleAttachment.css";

const SingleAttachment = (props) => {
  const saveFile = async (uuid) => {
    const attachment = await LocalAttchments.getAttachment(uuid);

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(attachment.blob, attachment.fileName);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(attachment.blob);
      a.href = url;
      a.download = attachment.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };

  const style = {
    padding: "15px",
    borderBottom: "1px solid lightgray",
    cursor: "pointer",
  };

  function getIcon() {
    if (props.mimeType == "document/pdf") {
      return "file-pdf";
    }

    if (props.mimeType?.includes("image")) {
      return "file-image";
    }

    return "file";
  }

  return (
    <div style={style}>
      <MDBRow>
        <MDBCol size="10" attachmentid={props.data.uuid} onClick={() => saveFile(props.data.uuid)}>
          <MDBIcon fas icon={getIcon()} />
          <span className="fileName">{props.data.name}</span>
        </MDBCol>
        <MDBCol size="2">
          {!props.isArchived || !props.completed && <MDBIcon fas icon="trash-alt" className="trashIcon" onClick={() => {
            props.showModal(props.data.uuid)
          }}/> }
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default SingleAttachment;
