import { MDBCheckbox } from "mdb-react-ui-kit";
import { db, Services } from "../components/db";
import { useState, useEffect } from "react";
import FieldConfig from "../components/FieldConfig";
const Checkbox = (props) => {
  const componentStyle = {
    padding: "15px",
  };
  const generateId = (label) => {
    if (label === undefined) {
      const min = Math.ceil(0);
      const max = Math.floor(9999);
      label = (Math.floor(Math.random() * (max - min + 1)) + min).toString();
    }
    return "checkbox_" + label.replace(" ", "");
  };
  let lockUpdate = false;

  const onChangeCallback = async (ev) => {
    if(lockUpdate){
      return;
    }
    var itemsArray = await Services.getSingleCellByUuid(props.formUuid, props.cell);
    itemsArray = itemsArray.filter(v => v !== props.checkedValue);
    console.log(ev.target.checked);

    if(ev.target.checked){
      itemsArray.push(props.checkedValue);
    }

    await Services.updateRecord(props.formUuid,{
      [props.cell]:itemsArray
    })

    return;
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fieldConfig, setFieldConfig] = useState(null);

  useEffect(() => {
    const doAsync = async () => {
      try {
        lockUpdate = true;
        setLoading(true);
        const loadedData = await getData(props);
        setData(loadedData);
        const fieldCfg = await FieldConfig("cbox_"+props.checkedValue);
        setFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        setLoading(false)
        lockUpdate = false;
      }
    }
    doAsync();
  }, []);

  if (loading) return <p>Loading...</p>;

  if (data == null)
    return (
      <p style={{ color: "red", fontWeight: "700" }}>
        Error in component {props.cell}
      </p>
    );

  return (
    <div style={componentStyle} className={fieldConfig.visible ? "" : "hidden"}>
      <MDBCheckbox
        name={generateId(props.label)}
        value=""
        id={generateId(props.label)}
        label={props.label}
        onChange={onChangeCallback}
        defaultChecked={data}
        className="checkbox"
        disabled={props.disabled || fieldConfig.mandatory}
      />
    </div>
  );
};

export default Checkbox;

const getData = async (props) => {
  const valuesArray = await Services.getSingleCellByUuid(
    props.formUuid,
    props.cell
  );
  return valuesArray.some((x) => x == props.checkedValue);
};
