import React, { useState, useEffect } from "react";
import SyncFlow from "../components/syncFlow/SyncFlow";
import {
  MDBBtn,
  MDBIcon,
  MDBProgress,
  MDBProgressBar,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
} from "mdb-react-ui-kit";
import { db, Drains, LocalAttchments, Map, Services } from "../components/db";
import { t } from "i18next";
import { toast } from "react-toastify";
import Tools from "../components/Tools";
import "./SyncPage.css";
import { useNavigate } from "react-router-dom";

function SyncPage(props) {
  const [percent, setPercent] = useState(0);
  const [basicModal, setBasicModal] = useState(false);
  const [staticModal, setStaticModal] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const interval = 10;
  var counter = 0;
  var size = 1;
  const navigate = useNavigate();
  const updateProgress = () => setPercent(((counter / size) * 100).toFixed(2));

  useEffect( () => {
    props.navbarProps.navbarTitle(t("sync.navbar"));
    props.navbarProps.hideFilterIcon();
    setIsOnline(window.navigator.onLine);
    setBackIcon();

    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));
  }, []);

  let style = {
    padding: "10px",
  };

  const toggleShow = () => setBasicModal(!basicModal);
  const toggleShowLoader = () => setStaticModal(!staticModal);

  const setBackIcon = () => {
    const backIcon = document.getElementById("nav-back");
    backIcon.classList.remove("navbar-hidden");
    backIcon.onclick = onBackClick;
  };

  const onBackClick = () => {
    navigate("/");
  };

  const onSyncClick = async () => {
    toggleShowLoader();
    await SyncFlow();
    setStaticModal(false);
  };

  async function syncMap(e) {
    var files = await fetch("/filestocache.txt");
    var txt = await files.text();
    const filesSpitted = txt.split("\r\n");

    size = filesSpitted.length;

    for (var i = 1; i < filesSpitted.length; i++) {
      try {
        updateProgress();
        ++counter;
        const tileFetched = await fetch(filesSpitted[i]);
        const tileBlob = await tileFetched.blob();
        var tileData = filesSpitted[i].split("\\");
        var y = tileData[4].replace(".png", "");
        await Map.addTile({
          x: tileData[3],
          y: y,
          z: tileData[2],
          path: filesSpitted[i],
          blob: tileBlob,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  const clearForms = async () => {
    toggleShow();

    await Drains.removeArchived();
    await Services.removeArchived();
    toast.info(t("sync.deleteOK"), {
      onClose: Tools.removeBlur(),
    });
  };

  return (
    <div style={style}>
      <h4>{t("sync.syncTitle")}</h4>
      <p>{t("sync.syncDesc")}</p>
      {!isOnline && (
        <p>
          Synchronisierung im Offline-Modus nicht möglich. Bitte erst mit dem
          Netzwerk verbinden.
        </p>
      )}
      {isOnline && (
        <MDBBtn
          color="light"
          className="bg-primaryDark"
          onClick={onSyncClick}
          style={{ margin: "20px", color: "white" }}
        >
          <MDBIcon fas icon="sync" />
          <span style={{ marginLeft: "5px" }}>{t("sync.syncBtn")}</span>
        </MDBBtn>
      )}
      <hr />
      <h4 color="light">{t("sync.removeArchived")}</h4>
      {/* <p>{t("sync.deleteDesc")}</p> */}
      <MDBBtn
        style={{ margin: "20px", color: "white" }}
        color="light"
        className="bg-primaryDark"
        onClick={toggleShow}
      >
        <MDBIcon fas icon="eraser" />
        <span style={{ marginLeft: "5px" }}>{t("sync.deleteBtn")}</span>
      </MDBBtn>
      {/* <hr/>
      <h4>Available space: {space} MB</h4> */}
      {/* <MDBBtn color="danger" onClick={syncBack} style={{ margin: "20px" }}>
        <MDBIcon fas icon="sync" />
        <span style={{ marginLeft: "5px" }}>Sync Back</span>
      </MDBBtn> */}
      {/* <hr />
      <h4>Sync map</h4>
      <p>
        If maps are not visible on your device, click on the button below. All
        the maps will be synchronized.
      </p>
      <MDBBtn color="success" style={{ margin: "20px" }} onClick={syncMap}>
        <MDBIcon fas icon="map" />
        <span style={{ marginLeft: "5px" }}>Sync maps</span>
      </MDBBtn>
      <MDBProgress height="20">
        <MDBProgressBar
          width={percent}
          valuemin={0}
          valuemax={100}
          bgColor="success"
        >
          {percent}%
        </MDBProgressBar>
      </MDBProgress> */}

      <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
        <MDBModalDialog centered size={"sm"}>
          <MDBModalContent className="modalWindow">
            <MDBModalBody>{t("sync.areUSure")}</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="light"
                className="bg-primaryDark text-white"
                onClick={toggleShow}
              >
                {t("no")}
              </MDBBtn>
              <MDBBtn color="light" onClick={clearForms}>
                {t("yes")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBModal
        staticBackdrop
        tabIndex="-1"
        show={staticModal}
        setShow={setStaticModal}
      >
        <MDBModalDialog centered>
          <MDBModalContent style={{ borderRadius: 0 }}>
            <MDBModalBody
              style={{ color: "black" }}
              className="border d-flex align-items-center justify-content-center"
            >
              <MDBSpinner grow color="danger"></MDBSpinner>
              <span className="ms-4">Bitte warten...</span>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}

export default SyncPage;
