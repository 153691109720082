import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.css";
import { Drains, LocalAttchments } from "../../../components/db";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import Tools from "../../../components/Tools";
import { MDBBtn } from "mdb-react-ui-kit";
import { status_started } from "../../FilterPage";

const SignaturePage = (props) => {
  const [landscape, setLandscape] = useState(false);
  const defaultSignatureWidth = 600;
  const search = useLocation().search;
  const formUuid = new URLSearchParams(search).get("uuid");
  const navigate = useNavigate();
  const canvas = useRef();

  useEffect(() => {
    const handleResize = () => {
      //window.location.reload();
    };

    if (window.innerHeight > window.innerWidth) {
      setLandscape(false);
    } else {
      setLandscape(true);
    }
    console.log("isHorizontal? " + landscape);

    window.addEventListener("resize", handleResize, false);
  }, [landscape]);

  useEffect(() => {
    //25.05.2023 - below is a try to implement orientation lock on mobile devices. Nowadays is disabled, because is not fully implemented ond iOS 16.5

    // if(Tools.detectInstalledPwa()){
    //   screen.orientation.lock("portrait").then(x => {
    //     console.log("Screen locked to landscape")
    //     setLockMsg("Screen locked to landscape")
    //   }).catch((e) => {
    //     console.warn(e)
    //     setLockMsg(e)
    //   })
    // }

    if (screen.orientation.type.includes("portrait")) {
      setLandscape(false);
    } else {
      setLandscape(true);
    }

    props.navbarProps.navbarTitle("Kundenunterschrift");
    props.navbarProps.hideFilterIcon();
    props.navbarProps.showSignatureIcons();
    setOnClickEvents();
  }, []);

  const setOnClickEvents = () => {
    const acceptIcon = document.getElementById("nav-acceptSignature");
    acceptIcon.onclick = saveSignature

    const clearIcon = document.getElementById("nav-clearSignature");
    clearIcon.onclick = clearSignature;

    const cancelIcon = document.getElementById("nav-cancelSignature");
    cancelIcon.onclick = onCancelSignature;
  };

  const onCancelSignature = () => {
    navigate("/task?uuid=" + formUuid + "&type=DRAIN&tab=2");
  };

  const saveSignature = async () => {
    const signatureName = ""; //document.getElementById("signatureName").value;
    const signatureExists = await LocalAttchments.checkIfExists(
      formUuid,
      "mimeType",
      "signature"
    );

    const outputCanvas = getResizedCanvas(
      canvas.current.getCanvas(),
      defaultSignatureWidth,
      defaultSignatureWidth * 0.5
    );

    await Drains.updateRecord(formUuid,{state: status_started})

    if (signatureExists) {
      const existingAttachment = await LocalAttchments.getAttachmentOtherValue(
        formUuid,
        "mimeType",
        "signature"
      );
      if (existingAttachment) {
        outputCanvas.toBlob(async (x) => {
          LocalAttchments.updateAttachment(existingAttachment.uuid, {
            blob: x,
            name: t("customersSingature") + signatureName,
          }).then(() => {
            navigate("/task?uuid=" + formUuid + "&type=DRAIN&tab=2");
          });
        });
      }
      return;
    }

    const fileUuid = uuidv4();
    const outputFileName = fileUuid + ".png";

    outputCanvas.toBlob((x) => {
      LocalAttchments.addAttachment({
        blob: x,
        formId: formUuid,
        name: t("signature") + " " + signatureName,
        fileName: outputFileName,
        mimeType: "image/png+signature",
        uuid: fileUuid,
      }).then(() => {
        navigate("/task?uuid=" + formUuid + "&type=DRAIN&tab=2");
      });
    });
  };

  const getResizedCanvas = (canvas, newWidth, newHeight) => {
    var tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = newWidth;
    tmpCanvas.height = newHeight;

    var ctx = tmpCanvas.getContext("2d");
    ctx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      newWidth,
      newHeight
    );

    return tmpCanvas;
  };

  const clearSignature = () => {
    canvas.current.clear();
  };

  const onDrawSignatureClick = async () => {
    if (!document.fullscreenElement) {
      console.log("No full screen - requesting");
      await document.documentElement.requestFullscreen();
    }
    await screen.orientation.lock("landscape-primary");
    console.log(screen.orientation);
  };

  //--uncomment when you want to enable "rotate to landscape notification"--
  //--23.05.2023 - It was commented because of problem with not working rotation on Android 13 with Google Chrome 113.

  // if (landscape == false) {
  //   return (
  //     <div
  //       className="d-flex justify-content-center align-items-center"
  //       style={{ height: "90vh" }}
  //     >
  //       {t('signature.rotateScreen')}
  //     </div>
  //   );
  // }

  //--end of area to uncomment

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.sigContainer}>
          <SignaturePad
            penColor="black"
            canvasProps={{ className: styles.sigPad }}
            ref={canvas}
            minWidth={2}
            maxWidth={4}
          />
        </div>
      </div>
    </div>
  );
};

export default SignaturePage;
