import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
} from "mdb-react-ui-kit";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { dbParameters, Drains, Services } from "../components/db";
import Tools from "../components/Tools";
import FieldConfig from "../components/FieldConfig";
import { t } from "i18next";
import { status_started } from "../pages/FilterPage";

const DropdownParameters = forwardRef((props, ref) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [fieldConfig, setFieldConfig] = useState(null);
  let lockUpdate = false;

  useImperativeHandle(ref, () => ({
    ForFutureUsage(val) {},
  }));

  useEffect(() => {
    lockUpdate = true;
    const doAsync = async () => {
      try {
        setLoading(true);
        const data = await getData(props);
        const currentVal = await getCurrentValue(props, data);
        setSelectedValue(currentVal);
        setData(data);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
        checkFieldConfig(fieldCfg);
      } catch (err) {
        console.error(err);
      }
      finally{
        setLoading(false);
        lockUpdate = false;
      }
    }
    doAsync();
  }, []);

  const checkFieldConfig = (fieldCfg) => {
    if (fieldCfg.mandatory && fieldCfg.readOnly) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and Read-only"
      );
    }

    if (!fieldCfg.visible && fieldCfg.mandatory) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and hidden"
      );
    }
  };

  const onClickCallback = async (event, e) => {
    const uuid = event.target.getAttribute("uuid");
    const label = event.target.textContent;

    const button = document.getElementsByClassName(dropodownClass)[0];

    button.setAttribute("uuid", uuid);
    button.textContent = label;

    props.setStatus(status_started);

    if (props.type == "service") {
      await Services.updateRecord(props.formUuid, { [props.cell]: uuid });
    } else {
      await Drains.updateRecord(props.formUuid, { [props.cell]: uuid });
    }
  };

  const component = {
    padding: "15px",
  };

  const dropdownStyle = {
    width: "100%",
  };

  const dropdownItemStyle = {
    width: "80%",
    maxHeight: "280px",
    overflowY: "auto",
    fontSize: "normal",
    overflowX: "hidden"
  };

  const label = "<auswählen>";
  const dropodownClass = "dropdown_" + props.parameters;

  if (loading) return <p>{t("loading")}</p>;

  if (!data) return <p>{t("noData")}</p>;

  return (
    <div style={component} className={fieldConfig.visible ? "" : "hidden"}>
      <label>{props.label}{fieldConfig.mandatory ? "*" : ""}</label>
      <MDBDropdown>
        <MDBDropdownToggle
          disabled={props.disabled}
          style={dropdownStyle}
          color={Tools.detectDarkTheme ? "light" : "dark"}
          outline
          className={dropodownClass}
          uuid={selectedValue != null ? selectedValue.uuid : ""}
        >
          {selectedValue != null ? selectedValue.label : label}
        </MDBDropdownToggle>
        <MDBDropdownMenu style={dropdownItemStyle}>
          {data
            .sort((a, b) => (a.label > b.label ? 1 : -1))
            .map((x) => {
              return (
                <MDBDropdownItem key={x.uuid} onClick={onClickCallback}>
                  <MDBDropdownLink uuid={x.uuid}>{x.label}</MDBDropdownLink>
                </MDBDropdownItem>
              );
            })}
        </MDBDropdownMenu>
      </MDBDropdown>
    </div>
  );
});

export default DropdownParameters;

async function getData(props) {
  if (props.parameters != "undefined") {
    if (props.parameters == "surcharge") {
      var outputVal = [];
      const val1 = await dbParameters.getParameterByKey("CG_TE_04");
      if(val1){
        outputVal.push({
          uuid: val1.uuid,
          label: val1.value,
          key: val1.key,
          id: val1.id,
        });
      }

      const val2 = await dbParameters.getParameterByKey("CG_TE_03");

      if(val2){
        outputVal.push({
          uuid: val2.uuid,
          label: val2.value,
          key: val2.key,
          id: val2.id,
        });
      }


      return outputVal;
    } else {
      console.error("Wrong parameter type");
    }
  }
}

const getCurrentValue = async (props, data) => {
  var selectedUuid = null;

  if (props.type == "service") {
    selectedUuid = await Services.getSingleCellByUuid(
      props.formUuid,
      props.cell
    );
  } else {
    selectedUuid = await Drains.getSingleCellByUuid(props.formUuid, props.cell);
  }

  if (!selectedUuid) {
    return null;
  }

  if (data != undefined) {
    var selectedValue = data.filter((x) => x.uuid == selectedUuid)[0];
    return selectedValue;
  } else {
    return null;
  }
};
