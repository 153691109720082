const Tools = {
  convertBlobToBase64: (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  },
  convertBGRtoRGB: (bgrInt) => {
    var red = bgrInt & 0xff;
    var green = (bgrInt >> 8) & 0xff;
    var blue = (bgrInt >> 16) & 0xff;

    red = fillHex(red);
    green = fillHex(green);
    blue = fillHex(blue);
    const rgb =
      "#" +
      fillHex(red.toString(16)) +
      fillHex(green.toString(16)) +
      fillHex(blue.toString(16));
    return rgb;
  },
  detectDarkTheme: () => {
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  },
  detectInstalledPwa: () => {
    return window.matchMedia("(display-mode: standalone)").matches || window.matchMedia("(display-mode: minimal-ui)").matches;
  },
  detectIfDev: () => {
    return process.env.NODE_ENV === "development";
  },
  removeBlur: () => {
    //TODO: Not working. To fix. 
    const id = document.getElementsByTagName("body")
    id[0].click();
  },
  uuidNull: () => {
    return "00000000-0000-0000-0000-000000000000";
  }, 
  dateToTimeStamp: (date) => {
    var datum = date
    return datum.getTime()/1000;
  },
  timeStampToDate: (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date;
  }
};

export default Tools;

const fillHex = (hexVal) => {
  if (hexVal.length == 1) {
    return ("0" + hexVal).toString();
  } else {
    return hexVal;
  }
};
