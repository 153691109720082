import { useLocation } from "react-router-dom";

const PrintErrorPage = () => {
    const search = useLocation().search;
    const code = new URLSearchParams(search).get("code");
    const message = new URLSearchParams(search).get("message");

    return(
        <>
        <h4>Print error</h4>
        <p>Code: {code}</p>
        <p>Message: {message}</p>
        </>
    )
}

export default PrintErrorPage;