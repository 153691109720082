import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Drains } from "../components/db";
import Tools from "../components/Tools";
import FieldConfig from "../components/FieldConfig";
import { load } from "detectrtc";
import { status_started } from "../pages/FilterPage";

const NumberInput = (props) => {
  const component = {
    padding: "15px",
  };

  const icon = {
    cursor: "pointer",
  };

  const [inputValue, setInputValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [fieldConfig, setFieldConfig] = useState(null);
  let lockUpdate = false;

  useEffect(() => {
    lockUpdate = true;
    const doAsync = async () => {
      try {
        setLoading(true);
        const fieldCfg = await FieldConfig(props.cell);
        setFieldConfig(fieldCfg);
        checkFieldConfig(fieldCfg);
        const val = await getData(props.formUuid, props.cell);
        if (val) {
          setInputValue(val);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
        lockUpdate = false;
      }
    };
    doAsync();
  }, []);

  useEffect(() => {
    console.log(lockUpdate);
    if (loading) {
      return;
    }
    valueOnChangeCallback();
  }, [inputValue]);

  const onInput = (ev) => {
    if (!isNaN(ev.target.value)) {
      setInputValue(ev.target.value);
    }
  };

  const increment = (ev) => {
    ev.target.parentNode.blur();
    if (inputValue.length == 0) {
      setInputValue(fieldConfig.step);
    } else {
      setInputValue(parseFloat(inputValue) + parseFloat(fieldConfig.step));
    }
  };

  const decrement = (ev) => {
    ev.target.parentNode.blur();
    if (inputValue.length == 0) {
      setInputValue(0);
    } else if (parseFloat(inputValue) - parseFloat(fieldConfig.step) <= 0) {
      setInputValue(0);
    } else {
      setInputValue(parseFloat(inputValue) - parseFloat(fieldConfig.step));
    }
  };

  const valueOnChangeCallback = async () => {
    if (lockUpdate) {
      return;
    }

    if (isNaN(inputValue)) {
      return;
    }

    if (props.cell != undefined && props.type != undefined) {
      const recordToChange = {
        [props.cell]: inputValue,
      };

      props.setStatus(status_started);
      if (props.type == "DRAIN") {
        await Drains.updateRecord(props.formUuid, recordToChange);
      } else if (props.type == "SERVICE") {
        await Services.updateRecord(props.formUuid, recordToChange);
      } else {
        return;
      }
    }
  };

  const checkFieldConfig = (fieldCfg) => {
    if (fieldCfg.mandatory && fieldCfg.readOnly) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and Read-only"
      );
    }

    if (!fieldCfg.visible && fieldCfg.mandatory) {
      console.error(
        "Error in field configuration. Field cannot be simultaneously mandatory and hidden"
      );
    }
  };

  const mandatory = () => {
    const selector = props.cell + "_mandatory";
    let result = false;
    try {
      result = props.data[selector];
      return result ?? fieldConfig.mandatory;
    } catch {
      return fieldConfig.mandatory;
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div
      style={component}
      className={props.cell + (fieldConfig.visible ? "" : " hidden")}
    >
      <label>
        {props.label}
        {mandatory() ? "*" : ""}
      </label>
      <MDBRow>
        <MDBCol sm="2" md="2" lg="1">
          <MDBBtn
            rounded
            color="light"
            className="bg-primaryUtil"
            disabled={props.disabled || fieldConfig.readOnly}
            onClick={decrement}
          >
            <MDBIcon fas size="2x" icon="minus" style={icon} />
          </MDBBtn>
        </MDBCol>
        <MDBCol sm="8" md="8" lg="10">
          <MDBInput
            type="text"
            value={inputValue}
            onInput={(ev) => {
              onInput(ev);
            }}
            className={props.cell + "input"}
            contrast={Tools.detectDarkTheme()}
            disabled={props.disabled || fieldConfig.readOnly}
          />
        </MDBCol>
        <MDBCol sm="2" md="2" lg="1" className="d-sm-flex justify-content-end">
            <MDBBtn
            rounded
            className="bg-primaryUtil"
            color="light"
            disabled={props.disabled || fieldConfig.readOnly}
            onClick={increment}
          >
            <MDBIcon fas size="2x" icon="plus" style={icon} />
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default NumberInput;

const getData = async (uuid, cell) => {
  const data = await Drains.getSingleCellByUuid(uuid, cell);
  return data ?? 0;
};
