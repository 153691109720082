import { MDBContainer } from "mdb-react-ui-kit";
import Checkbox from "../../../ui/Checkbox";
import Dropodown from "../../../ui/Dropdown";
import TextBox from "../../../ui/TextBox";
import ToggleButton from "../../../ui/ToggleButton";
import {
  MDBRow,
  MDBCol,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBBtn,
} from "mdb-react-ui-kit";
import { t } from "i18next";

const ServiceFormPage = (props) => {
  const getCoordinates = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          Drains.updateRecord(props.uuid, {
            latitudeRecorded: pos.coords.latitude,
            longitudeRecorded: pos.coords.longitude,
          }).then(() => {
            toast.success(t('coords.Setted'));
          });
        },
        (err) => {
          toast.error(err.message);
        }
      );
    } else {
      toast.error(t('coords.notSupported'));
    }
  };

  const onCompleteBtnClick = async () => {
    props.confirmModal();
  };

  return (
    <MDBContainer>
      <MDBRow className="mb-3">
        <MDBCol col="1">
          <MDBDropdown>
            <MDBDropdownToggle
              size="lg"
              style={{ width: "100%" }}
              className="bg-primaryDark"
            >
              {t("options")}
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem>
                <MDBDropdownLink onClick={getCoordinates}>
                  {t('task.getCoordinates')}
                </MDBDropdownLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBDropdownLink>{t('task.restoreStatus')}</MDBDropdownLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBDropdownLink href={"/task/previewpdf?uuid=" + props.uuid}>
                  {t('task.printConfirmation')}
                </MDBDropdownLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBDropdownLink href={"/task/signature?uuid=" + props.uuid}>
                  {t('signature')}
                </MDBDropdownLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBCol>
        <MDBCol col="1">
          <MDBBtn
            size="lg"
            color="success"
            style={{ width: "100%" }}
            onClick={onCompleteBtnClick}
            className="bg-primaryDark"
          >
            {t('task.complete')}
          </MDBBtn>
        </MDBCol>
      </MDBRow>

      <Dropodown
        label="Bearbitungszeit [h]"
        tableName="time"
        changeHandler={() => {}}
        cell="timeId"
        type="service"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Einsatzzeit"
        cell="kind"
        checkedValue="EFFORT"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Stillstandzeit"
        cell="kind"
        checkedValue="DOWNTIME"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <p style={{ fontWeight: "700", marginTop: "10px", marginBottom: "0px" }}>
        Nebenleistungen
      </p>
      <Checkbox
        label="Rohrspülung"
        cell="options"
        checkedValue="FLUSH"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Grubenreinigung"
        cell="options"
        checkedValue="CLEAN"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Grubenstilllegung"
        cell="options"
        checkedValue="SHUTDOWN"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Animpfen"
        cell="options"
        checkedValue="INOCULATE"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="Befüllung mit Wasser"
        cell="options"
        checkedValue="FILL"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <Checkbox
        label="andere Leistung"
        cell="options"
        checkedValue="OTHER"
        formUuid={props.uuid}
        disabled={props.completed}
      />
      <TextBox
        label="Bemerkung zum Auftrag"
        cell="remark"
        formUuid={props.uuid}
        type="service"
        disabled={props.completed}
      />
      <Dropodown
        label="Fahrzeug"
        tableName="vehicle"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        cell="executorId"
        vehicle
        type="service"
        disabled={props.completed}
      />
      <Dropodown
        label="Fahrer"
        tableName="employee"
        changeHandler={(val) => {}}
        formUuid={props.uuid}
        type="service"
        cell="employeeId"
        disabled={props.completed}
      />
      <ToggleButton cell="check" formUuid={props.uuid} type="service" />
    </MDBContainer>
  );
};
export default ServiceFormPage;
