import React from "react";
import Layout from "./layout/Layout";
import 'react-toastify/dist/ReactToastify.css'; 
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div id="snackbar">Eine Aktualisierung der App ist verfügbar. Bitte neu starten.</div>
      <Layout />
    </div>
  );
}

export default App;
