import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import "./TaskItem.css";
import { ReactComponent as DrainIcon } from "./../components/svg/ic_drain.svg";
import { ReactComponent as ServiceIcon } from "./../components/svg/ic_service.svg";
import { ReactComponent as PitTypeIcon } from "./../components/svg/ic_pit.svg";
import { ReactComponent as StarIcon } from "./../components/svg/ic_star.svg";
import { ReactComponent as EditIcon } from "./../components/svg/ic_edit.svg";
import { ReactComponent as CheckIcon } from "./../components/svg/ic_check.svg";
import { ReactComponent as FolderIcon } from "./../components/svg/ic_folder.svg";
import { ReactComponent as ErrorIcon } from "./../components/svg/ic_error.svg";
import { t } from "i18next";
import { PitType, Vehicle } from "../components/db";
import Tools from "../components/Tools";

function TaskItem(props) {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#000");
  const [vehicle, setVehicle] = useState("kein fahrzeug");

  useEffect(() => {
    const doAsync = async () => {
      try {
        setLoading(true);
        if (props.drain.pitTypeId) {
          const clr = await getPitTypeColor(props.drain.pitTypeId);
          setColor(clr);
        }

        if (props.drain.processorId) {
          const veh = await getVehicle(props.drain.processorId);
          setVehicle(veh);
        }
        setLoading(false);
      } catch (err) {
        console.warn(err);
      }
    };
    doAsync();
  }, []);

  function getStyle() {
    if (!props.severity || props.severity == "normal") {
      return "normalRow";
    } else if (props.severity == "warning") {
      return "warningRow";
    } else {
      return "errorRow";
    }
  }

  const checkPlanning = () => {
    if (props.drain.scheduleFrom && props.drain.scheduleTo) {
      return (
        convertDate(props.drain.scheduleFrom) +
        " - " +
        convertDate(props.drain.scheduleTo)
      );
    } else if (props.drain.scheduleFrom) {
      return convertDate(props.drain.scheduleFrom);
    } else if (props.drain.scheduleTo) {
      return convertDate(props.drain.scheduleTo);
    } else {
      return t("notPlanned");
    }
  };

  const convertDate = (dateArray) => {
    return (
      (dateArray[2] < 10 ? `0${dateArray[2]}` : dateArray[2]) +
      "." +
      (dateArray[1] < 10 ? `0${dateArray[1]}` : dateArray[1]) +
      "." +
      dateArray[0]
    );
  };

  if (loading) return <p>Loading...</p>;

  if (!color)
    return (
      <p style={{ color: "red", fontWeight: "700" }}>
        Error in component {props.cell}
      </p>
    );

  return (
    <MDBRow className={"p-2 border-bottom " + getStyle()}>
      <MDBCol size="2" className="d-flex align-items-center">
        <MDBRow>
          <MDBCol size="12">
            <div className="d-flex justify-content-center align-items-center">
              {props.type.toLowerCase() == "drain" ? (
                <DrainIcon className="taskTypeIcon taskTypeIconFill" />
              ) : (
                <ServiceIcon className="taskTypeIcon taskTypeIconFill" />
              )}
            </div>
          </MDBCol>
          <MDBCol size="12">
            <div className="d-flex justify-content-center align-items-center">
              <PitTypeIcon
                className="taskTypeIcon"
                fill={color}
                stroke="#000"
                strokeWidth="1"
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol size="8">
        <MDBRow className="d-flex align-items-center">
          <MDBCol sm="6" size="6">
            <div className="normalText">
              {props.drain.forname} {props.drain.name}
            </div>

            <span className="address">
              {props.drain.pitStreet} {props.drain.pitStreetNumber}
            </span>
            <div className="address">
              {props.drain.pitCity
                ? props.drain.pitCity
                : "Stadt nicht verfügbar"}
            </div>
          </MDBCol>
          <MDBCol sm="6" size="6">
            <div className="normalText">{checkPlanning()}</div>
            <div className="normalText">{vehicle}</div>
            <div className="normalText">{props.drain.ordered}m³</div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol size="2">
        <div className="d-flex align-items-center stateContainer">
          {props.drain.state == "CREATED" ? (
            <StarIcon className="taskTypeIcon taskTypeIconFill" />
          ) : null}
          {props.drain.state == "STARTED" ? (
            <EditIcon className="taskTypeIcon taskTypeIconFill" />
          ) : null}
          {props.drain.state == "COMPLETED" ? (
            <CheckIcon className="taskTypeIcon taskTypeIconFill" />
          ) : null}
          {props.drain.state == "ARCHIVED" ? (
            <FolderIcon className="taskTypeIcon taskTypeIconFill" />
          ) : null}
          {props.drain.state == "CONFLICT" ? (
            <ErrorIcon className="taskTypeIcon taskTypeIconFill" />
          ) : null}
        </div>
      </MDBCol>
    </MDBRow>
  );
}

export default TaskItem;

const getPitTypeColor = async (pitUuid) => {
  const colorBgr = await PitType.getColorByUuid(pitUuid);
  const colorRbg = Tools.convertBGRtoRGB(colorBgr);
  return colorRbg;
};

const getVehicle = async (vehicleUuid) => {
  const vehicle = await Vehicle.getByUuid(vehicleUuid);
  return vehicle;
};
