import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PdfDrainConfirmation } from "../components/PDF/PdfDrainConfirmation";
import { PdfServiceConfirmation } from "../components/PDF/PdfServiceConfirmation";

const ViewPdfPage = (props) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(true);
  const search = useLocation().search;
  const uuid = new URLSearchParams(search).get('uuid');
  const type = new URLSearchParams(search).get('type');

  useEffect(() => {
    const doAsync = async () => {
      try {
        props.navbarProps.hideFilterIcon();
        setPdfLoading(true);
        const pdfBlob = await readPdf(uuid,type);
        setPdfFile(pdfBlob);
        setPdfLoading(false);
      } catch (err) {
        setPdfLoading(false);
        console.error(err);
      }
    }
    doAsync();
  }, []);

  if (pdfLoading) return <p>PDF file loading...</p>;

  if (!pdfFile) {
    return <p>Error...</p>;
  }

  return (
    <iframe
      src={pdfFile}
      id="main-iframe"
      style={{
        width: "100%",
        height: '93vh',
        position: 'relative',
        top: 0,
        left: 0,
        border: 'none'
      }}
    ></iframe>
  );
};

 async function readPdf(uuid,type) {
   
   if(type === "drain"){
    const pdf = await PdfDrainConfirmation.createPdf(uuid);
    return URL.createObjectURL(pdf);
   }
   else{
     const pdf = await PdfServiceConfirmation.createPdf(uuid);
     return URL.createObjectURL(pdf);
   }
}

export default ViewPdfPage;
